.buttonWorkScheduleText{
  font-family: 'Roboto', sans-serif !important;
  color: #093c5d;
  font-weight: bold !important;
  font-size: 13px;
  margin-top: 7px;
  margin-right: 11.79px;
  margin-left: 30px;
}

.buttonWorkSchedule{
  background-color: white;
  border: none;
  border-radius: 8px;
  background-image: url('/src/assets/svg/icon-view.svg');
  background-repeat: no-repeat;
  background-position: 10px;
  cursor: pointer;
  height: 32px;
  border: 1px solid gray;
}

.buttonNewWorkSchedule{
  background-color: #fabb00;
  border: none;
  border-radius: 8px;
  background-image: url('/src/assets/svg/iconplus.svg');
  background-repeat: no-repeat;
  background-position: 10px;
  cursor: pointer;
  height: 32px;
  margin-left: 15px;
  margin-right: 25px;
}




.errorSemanaText{
  font-family: 'Roboto', sans-serif !important;
  color: red;
  font-weight: bold !important;
  font-size: 13px;
  margin-top: 7px;
  margin-right: 11.79px;
  margin-left: 30px;
}

.iconSelect{
  background-image: url('/src/assets/svg/icon-select.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 13px;
  position: absolute;
  right: 13px;
  bottom: 12px;
}

.buttonContainer{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: end;
}

.nameCalendarClock{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.666667px;
  color: #323232;
  margin-top: 0px;
}

.RectangleClock{
  margin-left: 25px !important;
  border-radius: 15px;
  border-radius: 4px;
  background-color: var(--backGroundRectangle);
  border-radius: 15px;
}

.titleSection{
  margin-left: 30px;
}

.calendarHeader{
  display:flex;
  align-items: center;
}

.calendarHeader .calendarInput{
  width:40%
}

.calendarHeader .headerSchedule{
  width:40%
}

.calendarHeader .calendarButton{
  width:20%
}

.calendarHeader .calendarButton .buttonWorkSchedule{
  width:90% ; 
}

@media (min-width: 1250px) {
  .calendarHeader .calendarButton .buttonWorkSchedule{
    width:90% ; 
    height:40px;
  }
}

.calendarHeader .containderImputsSearcher{
  border-radius: 4px;
  border-style: solid;
  border-color: #C7CCDC;
  width: 90%;
  margin: 5px 10px;
  height: 40px;
}

.calendarHeader .headerSchedule .previous{
  margin-left: 25px;
}

.clockQuestionContainer{
  margin: 10px 0px;
}

.iconoCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
  width: 13.18px;
  height: 13.18px;
  cursor: pointer;
  margin-bottom: auto;
  margin-left: auto;
  transition: 0.5s;
}

.daysOfWeek{
  width:100%;
  background-color: white;
  padding: 10px 5px 10px 5px;
  color: #093C5D;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 100%;
  border: 0.8px solid #C7CCDC;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  height: 43px;
  position: relative;
}

.daysOfWeekText {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-left: 10px;
  color: #093C5D;
  font-weight: 400;
}

.daysList{
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  z-index: 2;
}

.fieldsetUpdateFiltro {
  border-radius: 4px;
  height: 18px;
}

.checkboxDays {
  display: inline-block;
  position: relative;
  text-align: left;
}

.daysListButtonContainer{
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.dayListContainer {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;
}

.dayListName {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;
}

.addDaysButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  font-size: 14px;
  width: auto;
  height: 40px;
  border-radius: 8px !important;
  border: none;
  cursor: pointer;
}

.addDaysButtonIcon{
  background-image: url('/src/assets/svg/icon-save.svg');
  height: 16px;
  width: 16px;
  margin-right: 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.addDaysButtonText {
  margin-left: 5px;
  font-family: "Roboto", sans-serif;
  color: #093c5d;
  font-size: 16px;
  font-weight: bold;
}

.timePickerInput{
  height:43px;
}

.iconSave {
  background-image: url('/src/assets/svg/icon-save.svg');
  height: 16px;
  width: 16px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}

.containerFiltroRelojChecador{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;  
}

.selectFiltroCheck{
  margin-bottom: 10px;
}

.elementFiltro{
  display: flex;
  flex-direction: row;  
  margin-top: 15px;
  margin-bottom: 5px;
}

.dateInit{
  margin-right: 20px;
}

.contenedorMonth{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.backArrow{
  height: 16px;
  width: 16px;
  :hover{
    cursor:pointer
  }
}
.infoMont{
  height: 16px;
  width: 80px;
  text-align: center;
}
.nextArrow{
  height: 16px;
  width: 16px;
  :hover{
    cursor:pointer
  }
}

.containerFindColaborador{
  margin-left: 15px;
  display: flex;
  flex-direction: column;
    align-content: center;
  justify-content: center;
}

.containderImputsSearcher{
  width: 25%; 
}
.inputSearcherColaborador {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(255,255,255);
  outline: none;
  background-image: url('/src/assets/svg/flecha_azul_abajo.svg');
  background-repeat: no-repeat;
  background-position: calc(90%);
  
}

.divColaboradoresList
{
  margin-left: 7px;
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  width: 25%;
  z-index: 3;
}
.contenedorListaColaboradoresReloj {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;
}


.contenedorBtnAgregarColaborador{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.botonAgregarColaborador{
  background-color: #fabb00;
  border: none;
  border-radius: 8px;
  background-image: url('/src/assets/svg/icon-save.svg');
  background-repeat: no-repeat;
  background-position: 10px;
  text-align: center;
  cursor: pointer;
  height: 32px;
  margin-left: 15px;
  margin-right: 25px;
}

.btnRelojChecador{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.botonCancelarReloj {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 42px;
  border-radius: 8px !important;
  border: solid 1px #c7ccdc;
  color: #093c5d;
  font-weight: 500;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  cursor: pointer;
}
 


.btnDescargarLista{
  background-color: #fabb00;
  border: none;
  border-radius: 8px;
  background-image: url('/src/assets/svg/icono_descarga_documentos_fl.svg');
  background-repeat: no-repeat;
  background-position: 15px;
  text-align: center;
  cursor: pointer;
  width: 160px;
  height: 42px;
  margin-left: 15px;
  margin-right: 25px;
}

.infoActividades{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;    
  height: auto;    
  overflow-y: hidden;
}



.buttonColaborador{
  background-color: #093c5d;
  border: none;
  border-radius: 8px;  
  background-repeat: no-repeat;
  background-position: 10px;
  cursor: pointer;
  width: 440px;
  height: 21.5px;
  margin-left: -5px;
  
}

.buttonColaboradorText{
  font-family: 'Roboto', sans-serif !important;
  color: white;
  font-size: 13px;
  margin-top: 5px;
  margin-right: 11.79px;
  margin-left: 30px;
}
.contBtnCol{
  width: 95%;
  height: 32px;
  margin-left:15px;
  margin-top: 15px;  
}

.colaboradorActivo{
  margin-left:15px;
}
