  .dialogUser{
  position: fixed;
  top: 50px;
  left: 200px;  
  border-radius: 20px;
  background-color: #ffffff;
  }

  @media(min-width:1900px){
    .dialogUser{
        top: 190px;
        left: 500px;          
    }
}

  .contenedorTitulosColumnas{
    margin-top: 42px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 950px;
  }
  
  .titulosColumnas{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #222;
  }

  .contenedorTituloUno{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .contenedorTituloDos{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;   
    margin-bottom: 10px;
  }

  .btnFilter{
    font-family: "Roboto", sans-serif !important;
    background-color: #FFFFFF;
    border-color: #C7CCDC;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    font-weight: 800;
    border-radius: 4px !important;        
    color: rgb(9, 60, 93);    
  }

  .Filter{
    color: rgb(9, 60, 93);    
  }
  
  .contenedorColumnas{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .columnaTablaIzq{
    background-color: #f1f2f5;
    width: 401px !important;
    max-width: 401px;
    height: 384px;
    border-radius: 8px;
    padding-top: 14px;
    flex-wrap: wrap;
    overflow: scroll;
    display: flex;
    justify-content: center;
    margin-left: 20px;
  }

  .columnaTablaDer{
    background-color: #f1f2f5;
    width: 401px !important;
    max-width: 401px;
    height: 384px;
    border-radius: 8px;
    padding-top: 14px;
    flex-wrap: wrap;
    overflow: scroll;
    display: flex;
    justify-content: center;
    margin-right: 20px;
  }
  

  .botonIzquierda{
    margin-left: 9px;
    margin-right: 9px;
    margin-bottom: 8px;
  }
  
  .botonDerecha{
    margin-left: 9px;
    margin-right: 9px;
  }

  .imagenCursor{
    cursor: pointer;
  }
  .btnAddPrestacionColaborador{
    background-color: #fabb00;
    padding: 15px 15px;   
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px !important;
    height: 40px;
    width: auto;
    font-size: 14px;
    color: rgb(9, 60, 93);
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
  }