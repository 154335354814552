.badge-success {
    color: #212529;
    background-color: #367EEA;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.badge-secondary {
    color: #212529;
    background-color: #42DBBE;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.badge-info {
    color: #212529;
    background-color: #9C54E5;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.badge-warning {
    color: #212529;
    background-color: #FEC104;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.badge-danger {
    color: #212529;
    background-color: #FB337B;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.mymood-date-field{
    margin-right: 20px;
}

.surveyAddIcon{
    background-image: url('/src/assets/svg/icono_agregar_blue.svg');
    width: 20px;
    height: 10px;
    background-repeat: no-repeat;
}

.conteinerSurvey{
    background: #FFFFFF;
    border-radius: 4px;
    min-height: 300px;
}

.conteinerSurvey2{
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(9, 60, 93, 0.2);
    border-radius: 12px;
    padding:50px;
}

.containerSurveyRisk{
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(9, 60, 93, 0.2);
    border-radius: 12px;
    padding:50px;
}

.containerResponsesGraph{
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 31.78px;
    width: 440px;
}

.titleResponse{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.444444px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
}

.titleResponse p{
    margin-top: 5px;
    margin-bottom: 0px;
}

.divTitleResponse{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.361111px;
    color: #303030;
    margin: 0px 0 5px;
    opacity:0.6;
}
.divTextResponse{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0.361111px;
    margin:0 0 10px;
}
.textGreen{
    color: #11CA73;
}

.textResponse1{
    margin-left: 20px;
}

.textResponse2{
    width: 30%;
    margin-left: 30px;
}

.titleResponseGraph{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.444444px;
    color: #303030;
    text-align: center;
}

.titleResponseGraph p{
    margin-top: 0px;
    margin-bottom: 10px;
}

.barGraph{
    height: 200px;
    width: 100%;
}

.surveyTitle{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.666667px;
    color: #323232;
    margin-bottom: 10px;
}

.containerSurvey{
    margin-right: auto;
    width: 100%;

}

.containerSurveyTable{
    margin-left: auto;
    margin-right: auto;
    padding-top: 31.78px;
    padding-bottom: 0px;
    width: 80%;

}

.conteinerAllQuestion{
    display: flex;
    margin-bottom: 10px;
    margin-bottom: 20px;
}

.conteinerAllQuestionLine{
    border-bottom: 1px solid #C7CCDC;
    width: 100%;
    margin-right: auto;
}

.surveySeparator{
    border-bottom: 1px solid #C7CCDC;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.conteinerAllQuestionTable{
    width: 100%;
    margin-right: auto;
}

.conteinerQuestion{
    width: 100%;
    margin-bottom: 20px;
}

.question{
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #838383;
}

.botonAgregar{
    width: 270px;
    height: 40px;
    background-color: #FABB00;
    cursor: pointer;
    border: none;
    border-radius: 8px !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.iconoAgegar{
    background-image: url('/src/assets/svg/icono_prefactura.svg');
    width: 20px;
    height: 10px;
    margin-right: 6.5px;
    filter: opacity(0.4) drop-shadow(0,0,0 red);
    background-repeat: no-repeat;
}

.textoBotonAgregar{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.36px;
    color: #093C5D;
}

.contenedorBotones{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
}

.botonCancelar{
    background-color: #fff;
    border: 0.8px solid #C7CCDC;
    border-radius: 8px;
    width: 110px;
    height: 40px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #093C5D;
    cursor: pointer;
    margin-right: 16px;
}

.botonSiguiente{
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    background-color: #FABB00;
    border: none;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
    color: #093C5D;
}

.iconoSiguiente{
    background-image: url('/src/assets/svg/icono_send.svg');
    width: 15.59px;
    height: 14.17px;
    margin-left: 12.50px;
}

.linea{
    opacity: 0.5;
}

.pc_contenedorBuscador{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 21px;
    border: 1px solid #c7ccdc;
    height: 47px;
    border-radius: 8px;
}

.pc_inputBuscador{
    display: flex;
    align-items: center;
    width: 420px;
    min-width: 420px;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    height: 47px;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #303030;
    background-image: url('/src/assets/svg/icono_buscar_dos.svg');
    background-repeat: no-repeat;
    background-position: 95% 50%;
}

.pc_inputBuscador::placeholder{
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #303030;
    opacity: 0.6;
}

.pc_inputBuscador:focus{
    border: none !important;
    outline: none;
}


.pc_inputBuscador:active{
    border: none !important;
    outline: none;
}

.pc_iconoBuscador{
    background-image: url('/src/assets/svg/icono_buscar_dos.svg');
    width: 18.12px;
    height: 18.12px;
}

.pc_contenedorTabla{
    width: 100%;
    height: auto;
    overflow-x: auto;
    white-space: nowrap;
}

.pc_tablaPrincipal{
    border-collapse: collapse;
    margin-bottom: 30px;
    width: 100%;
}

.pd_contenedorTitulos{
    background-color: #e9ebf1;
    height: 32px;
    border-radius: 10px !important;
}

.pc_columnaTitulos{
    margin-right: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.36px;
}

.pc_columnaTitulosSurvey{
    margin-right: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.36px;
}

.columnDate{
    padding-left: 32px;  
}

.pc_columnaTitulosDate{
    padding-left: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.36px;
}

.pc_columnaTitulosQuestion{
    width: 12%;
    margin-right: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.36px;
}

.pc_columnaDatos{
    background-color: #fff ;
    border-bottom: solid 1px #c7ccdc;
    font-family: 'Roboto', sans-serif;
    color: #303030;
    opacity: 0.8;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    height: 37px;
}

.divDoc{
    display: flex;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}
.statusLabel{
    min-width: 50px;
}

.divIconos{
    display: flex;
    width: 80px;
    align-items: center;
}

.dialogContainer {
    padding:15px;
}

.containerTitle h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #093C5D;
}


.centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
    text-align: center;
}

.contenedorCerrarModal{
    width: 5%;
    display: flex;
    margin-top: 0px;
    align-items: center;
}

.iconoCerrarModal{
    background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
    width: 13.18px;
    height: 13.18px;
    cursor: pointer;
    margin-bottom: auto;
    margin-left: auto;
}
  
.iconoCerrarModal:hover{
    filter: opacity(0.4) drop-shadow(0 0 0 red); 
}

.dialogoContento 
{
  max-width: 100%!Important;
 
}

.ColumnaEditarPuesto
{
  margin-left: 48px;
  margin-right: 48px;
}

.botonCancelar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    color: #093c5d;
    font-weight: bold;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10px;
}

.containerAnswers{
    background: #093C5D;
    border-radius: 5px;
    display: flex;
    width: 140px;
    margin-bottom: 5px;
}

.containerCircle {
    display: inline-block;
    text-align: center;
    height: 36px;
    width: 86%;
}

.circleName{
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 4px;
    height: 35px;
    width: 100%;
}

.circle{
    width: 14px;
    height: 14px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-left: 3px;
    margin-right: 3px;
}

.circleText{
    font-family: 'Roboto';
    font-style: normal;
    /*font-weight: 700;*/
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin-left: 5px;
  }

  .containerOrder{
    font-family: 'Roboto', sans-serif;
    color: #303030;
    opacity: 0.8;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    height: 37px;
    line-height: 60px;
    display: flex;
    margin-left: auto;
}

.divOrder{
    margin-left: 10px;
    cursor: pointer;
    display: flex;
}

.selectSize{
    height: 41px !important;
    padding-bottom: 0px !important;
}

.imgLowerPosition{
    background-image: url('/src/assets/svg/imgLowerPosition.svg');
    background-repeat: no-repeat;
    width: 17.18px;
    height: 13.18px;
    cursor: pointer;
    margin-top: 23px;
    margin-left: 5px;
}

.sortAsc{
    background-image: url('/src/assets/svg/icono-ordenar-asc.svg');
    background-repeat: no-repeat;
    width: 17.18px;
    height: 13.18px;
    cursor: pointer;
    margin-top: 23px;
    margin-left: 5px;
}
.sortDesc{
    background-image: url('/src/assets/svg/icono-ordenar-desc.svg');
    background-repeat: no-repeat;
    width: 17.18px;
    height: 13.18px;
    cursor: pointer;
    margin-top: 22px;
    margin-left: 5px;
}

.imgLowerPositionOpen{
    background-image: url('/src/assets/svg/imgLowerPositionOpen.svg');
    background-repeat: no-repeat;
    width: 17.18px;
    height: 13.18px;
    cursor: pointer;
    margin-top: 22px;
    margin-left: 5px;
  }

	  .questionContainer{
	    background: #FFFFFF;
	    box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
	    border-radius: 10px 10px 10px 10px;
	    position: absolute;
	    padding: 25px;
	    z-index: 2;
	    width: 275px;
	}
	
	.containerSurveyFilter{
	    margin-left: 30px;
        padding-top:25px;
	    padding-bottom: 0px;
	    display: flex;
	    height: 50px;
	}

    .containerRiskSurveyFilter{
	    margin-left: 30px;
	    margin-right: 5%;
	    padding-top: 25px;
	    padding-bottom: 0px;
	    width: 95%;
	    display: flex;
	    height: 50px;
	}
    .containerRiskTitle{
        margin-left: 30px;
    }
  /* modal de comentarios de tests */

  .testTitle{
    color: #093c5d;
    font-size: 20px;
    font-weight: bold; 
    text-align: center;
    margin-bottom: 10px;
  }

  .testSubtitle{
    color: #093c5d;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    margin: 0px;
  }

  .testMainSubtitle{
    color: #093c5d;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .testSubtitleBlack{
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
  }

  .testAverage{
    color: #093c5d;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin: 0px 0px 0px;
  }

  .testBar{
    background: #dfdfdf;
    border-radius: 5px;
    height: 20px;
    width: 100%;
  }

  .testBarContainer{
    margin-bottom: 10px;
  }

  .testBarFilled{
    background:#f7b801;
    height: 100%;
    border-radius: 5px;
  }

  .testTextbold{
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }

  .commentProfile{
    width: 50px;
    height:50px;
    border-radius: 50px;
  }

  .commentAuthor{
    font-size: 13px;
    font-weight: bold;
  }

  .commentDays{
    font-size: 13px;
  }

  .commentText{
    font-size: 13px;
  }

  .testCommentList{
    max-height: 250px;
    overflow: auto;
  }

  .testStarGraph{
    text-align: center;
    margin-bottom: 20px;
  }

  .MuiTabs-root{
    min-width: 1000px;
  }

  .contenedor2{
    min-width: 1000px;
  }
  
  .containerPadding{
    padding: 20px;
  }

  /* For desktop: */
@media only screen and (min-width: 768px) {
    .testStarGraph{
        text-align: center;
        margin: 0px;
    }
   .MuiTabs-root{
        min-width: auto;
    }
    .contenedor2{
        width:100%;
    }
    
}
