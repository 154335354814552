
.conteinerbotonAgregar{
  width: 180px;
  margin-left: auto;
}

.botonAgregar{
  background-color: #fabb00;
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 180px  ; 
  background-image: url('/src/assets/svg/plusCalendar.svg');
  background-repeat: no-repeat;
  background-position-x: 20px;
  background-position-y: 12.5px;
  cursor: pointer;
}

.botonAgregar:active{
  transition: 0.9ms;
  background-color: #fddb73;
}

.textoBoton{
  font-family: 'Roboto', sans-serif !important;
  color: #093c5d;
  font-weight: bold !important;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 0px;
  margin-left: 10px;
}

.textoBotonn{
  font-family: 'Roboto', sans-serif !important;
  color: #093c5d;
  font-weight: bold !important;
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: 5px;
  margin-right: 0px;
  margin-left: 18px;
}

.dialogContainer {
  padding:15px;
}
.contenedorTextoMenu{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textoMenuEliminar{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 19px;
}
  
.iconoMenuEliminar{
  width: 18px;
  height: 18px;
}
  
.textoMenuEditar{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 37px;
}
.iconoMenu{
  width: 11px;
  height: 11px;
}
.addIconMenu{
  color: #2186C6 !important;
  margin-left: -15px;
}

.dialogFields {
    margin-bottom:24px !important;
}

.centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
    text-align: center;
}

.tituloEnterNewCalendar{
  display: flex;
  align-content: center;
  justify-content: center !important;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.centerContainerTitle{
  display: flex;
  justify-content: center !important;
  margin-left: 5%;
}
.centerContainerOption{
  display: flex;
  align-content: center;
  justify-content: center !important;
  margin-left: -5%;
  margin-top: -2%;
}

.moreOption {
  color: #fabb00;
  font-size: 35px !important;
}

.moreOptionNewCalendar {
  color: #fabb00;
  font-size: 35px !important;
  width: 30px !important;
  height: 30px !important;
}

.centerContainerCount{
  display:flex;
  align-content: center;
  justify-content: center !important;
  border: 1.08913px solid #093C5D;
  border-radius: 12.63px;
  margin:10%;
  padding:10px;  
}


.endContainer{
    display: flex;
    align-content: center;
    justify-content: flex-end !important;
}

  .flex{
    display: flex;
    justify-content: center;
  }
    
  .tituloDatosUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #222;
  }
  .tituloNombreUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #323232;
    margin-top: 10px;
  }
  .tituloNombreCard{
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #323232;
    margin-top: 10px;
    cursor: pointer;
  }
.tituloRazon{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #323232;
    opacity: 0.6;
}
.tituloEvent {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #093C5D;
  margin-top: 10px;
  font-weight: 500;
}
.tituloEventNumber {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  color:  #303030;
  margin-top: 10px;
  font-weight: 500;
}
.tituloEnter{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #323232;
    margin-top: 10px;
    
}
.calendarContainer{
    margin-top: 1% !important;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    max-height:70vh;
    overflow: scroll;
    
}

.calendarContainerName{
    margin:10px
}
    
  .botonEliminar{
    background-color: #d31313 !important;
    padding: 15px 15px;
    margin-top: 20px;
    margin-right: 50px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgba(211, 19, 19, 0.4);
    font-size: 16px;
    color: #ffffff !important;
  }

.selectedTab{
  background-color: #093C5D !important;
  color:white !important;
  opacity:1 !important
}
 
.unselectedTab{
  background-color: #022338 !important;
  color:white !important;
  opacity:1 !important

}

.columnaTres{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom:24px ;
}

.contenedorFechas{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
  border: solid 1px #c3c3c3; 
  padding-left: 18px;
  padding-right: 12px;
  border-radius: 4px;
}

.textoFechas{
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #222;
  opacity: 0.6;
}

.calendario{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.titleCalendar {

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.666667px;

    color: #323232;

}

.conteinerCalendarName{
  width: 100%;
  text-align: center;
  
}

.conteinerCalendar{
  display: flex;
  width: 100%;
}

.conteinerCard{
  width: 100%;
}

.conteinerCardNewCalendar{
  width: 95%;
}

.conteinerMenu{
  width: 40px;
}

.conteinerMenuNewCalendar{
  width: 15px;
}

.conteinerMenuCalendar{
  margin-top: 8px;
}

.buttonCancelCalendar{
  width: 129px;
  height: 40.96px;
  border: 1px solid #C7CCDC !important;
  border-radius: 8px !important;
  cursor: pointer;
}

.buttonCancelCalendarText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px !important;
  color: #093C5D;
}

.buttonSaveCalendarText{
  width: 136px;
  height: 40.96px;
  background: #FABB00;
  border-radius: 8px !important;
  margin-left: 10px;
}

.conteinerSave{
  margin-left: 24px;
}

.buttonSaveCalendar{
  width: 136px;
  height: 40.96px;
  background: #FABB00;
  border-radius: 8px !important; 
  background-image: url('/src/assets/svg/SaveCalendar.svg');
  background-repeat: no-repeat;
  background-position: 20px;
  cursor: pointer;
  border-color:transparent
}

.buttonSaveCalendar2{
  width: 136px;
  height: 40.96px;
  background: #FABB00;
  border-radius: 8px !important; 
  background-image: url('/src/assets/svg/Save.svg') !important;
  background-repeat: no-repeat;
  background-position: 20px;
  cursor: pointer;
  border-color:transparent
}

.buttonSaveCalendarText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px !important;
  color: #093C5D;
}

.conteinerCalendarFields{
  width: 100%;
}

.fieldsetInputCalendarFields{
  border: 0.8px solid #C7CCDC;
  border-radius: 4px; 
  margin-bottom: 24px !important;
}

.fieldsetTitleCalendarFields{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #093C5D;
  
}

.dateContainerCalendar{
  display: flex;
}

.dateCalendar{
  width: 100%;
  margin-right: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.8;
}

.contenedorCerrarModal{
  width: 5%;
  display: flex;
  margin-top: 0px;
  align-items: center;
}

.iconoCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
  width: 13.18px;
  height: 13.18px;
  cursor: pointer;
  margin-bottom: auto;
  margin-left: auto;
}

.iconoCerrarModal:hover{
  filter: opacity(0.4) drop-shadow(0 0 0 red); 
}

.containerTitle{
  display: flex;
}

.contenedorSelecioneColaborador
{
  text-align: center;
  margin: auto;
  display: flex;
}

.contenedorColaborator {
  box-sizing: border-box;
  height: auto;
  padding-left: 10%;
  padding-right: 10%;
  /*width: 583px;*/
  margin-bottom: 20px;
}

.divtotalesUniverso{
  background-color: #093c5d;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  color: white;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.textoDivTotales {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin-right: 10px;
  color: white;
  font-weight: 400, "Regular";
  margin: auto;
}

.contenedortotales
{
  text-align: center;
  width: 80%;
  margin: auto;
  display: flex;
}

.contenedortotalesAreas
{
  text-align: center;
  margin: auto;
  display: flex;
}

.divtotalesSelecionados {
  background-color: #093c5d;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  color: white;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 100%;
  cursor: pointer;
}

.textoDivTotalesSelecionados {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin-right: 10px;
  color: white;
  font-weight: 400, "Regular";
  margin: auto;
}

.divtotalesyellowl {
  background-color: #fabb00;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  border-radius: 0px 8px 8px 0px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 10%;
  cursor: pointer;
}

.DivSeleccionadosUniverso
{
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 50%;
  margin-top: 45px;
  padding: 10px;
  z-index: 2;
  top: 24%;
  left: 165px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contenedorListaAvailableCollaborators {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;

  
}

.listaNombresAvailableCollaborators {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;
}

.checkboxitemAvailableCollaborators {
  display: inline-block;
  position: relative;
  text-align: left;
}

.checkboxitemAvailableCollaborators input[type="checkbox"]:checked + label:after {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.checkboxitemAvailableCollaborators label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border: 1px solid #c7ccdc;
  background-color: transparent;
}

.checkboxitemAvailableCollaborators input[type="checkbox"] {
  display: none;
}

.botonAgregar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  font-size: 14px;
  width: 129px;
  height: 40px;
  border-radius: 8px !important;
  margin-left: 24px;
  border: none;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;
}

.botonAgregarNewCalendar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  font-size: 14px;
  width: 200px;
  height: 40px;
  border-radius: 8px !important;
  margin-left: 24px;
  border: none;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;
}

.botonAgregarNewCalendar2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  font-size: 14px;
  width: 200px;
  height: 40px;
  border-radius: 8px !important;
  margin-left: 24px;
  border: none;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;
  background-image: url('/src/assets/svg/iconplus.svg');
  background-repeat: no-repeat;
  background-position: 20px;
}

.pc_iconoAgregarDos{
  background-image: url('/src/assets/svg/icono_agregar_dos.svg');
  height: 13.33px;
  width: 13.33px;
  margin-right: 6.83px;
}

.textoAgregar {
  margin-left: 12px;
  font-family: "Roboto", sans-serif;
  color: #093c5d;
  font-size: 16px;
  font-weight: 500;
}

.DivSeleccionados {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 40%;
  margin-top: 45px;
  padding: 10px;
  z-index: 1;
  top: 24%;
  left: 165px;
}

.checkboxitemSelecionados {
  display: inline-block;
  position: relative;
  text-align: left;


}

.contenedorLista {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;
}

.listaNombres {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;

  
}

.checkboxitemSelecionados {
  display: inline-block;
  position: relative;
  text-align: left;


}

.botonEliminarList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #fbe7e7;
  color: #093c5d;
  font-weight: 500;
  background-color: #fbe7e7;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  cursor: pointer;
}

.EliminarImg {
  margin-right: 5px;
}

.divtotalesyellow {
  background-color: #fabb00;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  border-radius: 8px 0px 0px 8px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 10%;
  cursor: pointer;
}

.contenedorTotalesText{
  width: 100%;
  
}

.DivSeleccionadosUniverso
{
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 50%;
  margin-top: 45px;
  padding: 10px;
  z-index: 2;
  top: 24%;
  left: 165px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contenedorListaAvailableCollaborators {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;

  
}

.listaNombresAvailableCollaborators {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;
}

.checkboxitemAvailableCollaborators {
  display: inline-block;
  position: relative;
  text-align: left;
}

.checkboxitemAvailableCollaborators input[type="checkbox"]:checked + label:after {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.checkboxitemAvailableCollaborators label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border: 1px solid #c7ccdc;
  background-color: transparent;
}

.checkboxitemAvailableCollaborators input[type="checkbox"] {
  display: none;
}

.botonAgregar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  font-size: 14px;
  width: 129px;
  height: 40px;
  border-radius: 8px !important;
  margin-left: 24px;
  border: none;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;
}

.pc_iconoAgregarDos{
  background-image: url('/src/assets/svg/icono_agregar_dos.svg');
  height: 13.33px;
  width: 13.33px;
  margin-right: 6.83px;
}

.textoAgregar {
  margin-left: 12px;
  font-family: "Roboto", sans-serif;
  color: #093c5d;
  font-size: 16px;
  font-weight: 500;
}

.DivSeleccionados {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 40%;
  margin-top: 45px;
  padding: 10px;
  z-index: 1;
  top: 24%;
  left: 165px;
}

.DivSeleccionadosCollaborators {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 40%;
  margin-top: 45px;
  padding: 10px;
  z-index: 1;
  bottom: 20%;
  left: 165px;
}

.checkboxitemSelecionados {
  display: inline-block;
  position: relative;
  text-align: left;


}

.contenedorLista {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;
}

.listaNombres {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;

  
}

.checkboxitemSelecionados {
  display: inline-block;
  position: relative;
  text-align: left;


}

.botonEliminarList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #fbe7e7;
  color: #093c5d;
  font-weight: 500;
  background-color: #fbe7e7;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  cursor: pointer;
}

.EliminarImg {
  margin-right: 5px;
}

.menuListNewCalendar{
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 16px !important;
}

.menuListOptionNewCalendar{
  padding: 0px !important;
}