.pc_contenedorTabla{
  width: 100%;
  /* margin-left: 26px; */
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.pc_tablaPrincipal{
  border-collapse: collapse
}

.pd_contenedorTitulos{
  background-color: #e9ebf1;
  height: 32px;
  border-radius: 10px !important;
}

.checkboxitem {
  display: inline-block;
  position: relative;
  text-align: left;
}

.pc_columnaTitulos{
  min-width: 220px;  
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #303030;
  line-height: 15px;
  letter-spacing: 0.36px;
}

.pc_columnaDatos{
  background-color: #fff ;
  border-bottom: solid 1px #c7ccdc;
  font-family: 'Roboto', sans-serif;
  color: #303030;
  opacity: 0.8;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  height: 37px;
}

.pc_bordePrimeraColumna{
  border-bottom: solid 1px #eaecf2;
  opacity: 0.8;
}

.checkboxitem input[type="checkbox"]:checked + label:after {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}