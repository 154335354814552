
.columnaGeneralPuesto{
    width: 100%;
    height: 100%;
  }
  
  .titulos{
    font-weight: bold;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 80%;
  }
  
  .filaInput{
    display: flex;
    justify-content: flex-start;
  }
  
  .espacioTitulosEdit{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px!Important;
    height: 40px;
    font-size: 14px;
    width: 100%;
  }
  
  .espacioTitulosTextarea{
    margin-top: 10px;
    font-weight: bold;
  }
  
  .espacioInputs{
    margin-top: 10px;
  }
  
  .contenedorizquierdo{
    margin-left: 10%;
  }
  
  .inputSelect{
    width: 100%;
    height: 40px;
    background-color: white !important;
  }
  .inputSelect:focus{
    background-color: rgba(0, 0, 0, 0) !important;
    color: yellow!important;
    height: 40px !important;
  }
  
  .optionSelect{
    height: 40px !important;
    background-color: white !important;
  
  }
  
  .optionSelect:focus{
    background-color: none !important;
    height: 40px !important;
    color: yellow!important;
  }
  
  .tituloDescripcion{
    font-size: 13px;
  }
  
  .espacioTextArea{
    width: 100%;
    font-size: 100px !important;
    color: red !important;
  }
  
  .espacioTextArea::placeholder{
    font-size: 100px !important;
    color: red !important;
  }
  
  .busquedaColaborador{
    margin-left: 10%;
    margin-top: 10px;
  }
  
  .botonAgregarPuesto{
    background-color: #fabb00;
  }
  
  .contenedorUsuarios{
    height: 270px!Important;
    overflow-y: scroll;
  }
  
  .contenedorUsuariosPuesto{
    height: 100%;
  }
  
  .contenedorUsuariosPuestoInfo{
    height: 100%;
    margin-left: 25px;
  }
  .contenedorListaUsuariosPuesto{
    border-radius: 4px;
    border-radius: 4px;
    height: 50px;
    margin-top: 2px;
    width: 100%;
    list-style: none;
  }
  .contenedorLista{
    border-radius: 4px;
    border-radius: 4px;
    height: 50px;
    margin-top: 2px;
    width: 99%!Important;
    border: solid 1px #c7ccdc!Important;
    background: none!Important;
  
  }
  .textoListaUsuario{
    color: #093c5d;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  
  .correoListaUsuario{
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222;
    opacity: 0.6;
  }
  
  .bottonAgregarUsuario{
    background-color: #fabb00;
    border-radius: 12px;
    margin: 5px 10px 4px 66px;
    padding: 4px 6px 4px 13px;
    box-shadow: 0 2px 4px 0 rgba(250, 187, 0, 0.4);
    border: none;
    cursor: pointer
  }
  
  .textoBotonAgregaUsuario{
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #093c5d;
    cursor: pointer;
  }
  .ulLista{
    margin: 0px;
      padding: 0px;
    margin-left: 0%!Important;
  }
  
  .alineacionPuestoUsuario{
    display: flex;
    justify-content:space-between;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  
  .iconoEliminaUsuario{
    display: flex;
    justify-content:flex-end;
  }
  
  .iconoEliminaUsuarioIMG{
    cursor: pointer;
  }
  
  .iconoEliminaUsuario >  button{
    border: none;
    background: none;
  }
  
  .contenedorListaUsuarioBoton{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ContenedorBotonCrearOrganigrama{
    position: absolute;
    z-index: 1;
    top: 40px;
  }
  
  
  .contenedorTituloModale
  {
    width: auto!important;
    position: initial;
  }
  
  .ColumnaEditarPuesto
  {
    margin-left: 48px;
    margin-right: 48px;
  }
  
  .dialogoContento 
  {
    max-width: 100%!Important;
  }

  .botonCancelar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 129px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    color: #093c5d;
    font-weight: bold;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 10px;
  }

  .pc_contenedorTabla{
    width: 98%;
    height: auto;
    overflow-x: auto;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .pc_tablaPrincipal{
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
  }

  .pd_contenedorTituloSaldos{
    display: flex;
  }

  .pd_contenedorTitulos{
    background-color: #e9ebf1;
    height: 32px;
    border-radius: 10px !important;
  }

  .pc_columnaTitulos{
    min-width: 150px;
    margin-right: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.30px;
  }

  .pc_columnaTitulosConcepto{
    min-width: 270px;
    margin-right: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.36px;
  }

  .tableDivConcept{
    width: 150px;
  }

  .tableDivConcept p{
    text-overflow: ellipsis;
    overflow: hidden;
  }
  

  .checkboxitem {
    display: inline-block;
    position: relative;
    text-align: left;  
  }

  .pc_columnaDatos{
    background-color: #fff ;
    border-bottom: solid 1px #c7ccdc;
    font-family: 'Roboto', sans-serif;
    color: #303030;
    opacity: 0.8;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    height: 37px;
  }

  .containerTitle{
    display: flex;
  }

  .centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
    text-align: center;
    width: 100%;
  }

  .contenedorCerrarModal{
    width: 5%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    --margin-right: 8px;
  }

  .iconoCerrarModal{
    background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
    width: 13.18px;
    height: 13.18px;
    cursor: pointer;
    margin-bottom: auto;
    margin-left: 25px;
    
  }

  .iconoDeleteModal{
    background-image: url('/src/assets/svg/iconoDelete.svg');
    /*width: 11.18px;*/
    height: 13.18px;
    cursor: pointer;
    margin-bottom: auto;
    background-repeat: no-repeat;
    background-position-x: 50%;
  }

  .iconoDocModal{
    width: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .conteinerNoReg{
    width: 97%;
  }

  .divNoReg{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .textNoReg{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.257143px;

    color: #303030;
  }

  .containerOptions{
    display: flex;
    width: 80%;
  }