.titulo{
  font-size: 18px;
  color: #093c5d;
  width: 660px;
  font-weight: bold; 
  text-align: center;
}


.fila{
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.tituloFila{
  font-size: 13px;
  color: #303030;
  opacity: 0.8;
  letter-spacing: 0.36px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
}

.input{
  box-sizing: border-box;
  width: 250px;
  height: 32px;
  border: solid 1px #c7ccdc;
  padding-left: 10px;
  border-radius: 8px;
}

.contenedorError{
  width: 200px;
  margin-bottom: 4px;
  height:10px;
  text-align: center;
  color:  #E0544B ;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
}

.contenedorErrorSelect{
  width: 100%;
  margin-bottom: 4px;
  margin-left: 10px;
  height:5px;
  text-align: center;
  color:  #E0544B ;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
}

.select{
  box-sizing: border-box;
  width: 200px;
  height: 32px;
  border: solid 1px #c7ccdc;
  padding-left: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #c7ccdc!important;
  margin-left: 200px;
}

.contenedorBotones{
  margin-top: 30px;
  width: 660px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.botonGuardar{
  width: 129px;
  height: 42px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #093C5D;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #FABB00;
  border:none;
  border-radius: 8px;
  cursor: pointer;
}

.botonCancelar{
  width: 129px;
  height: 42px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #093C5D;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #C7CCDC;
  border-radius: 8px;
  cursor: pointer;
}