@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


.contenedorPrincipal{
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 8px;
  margin-left: -25px;
  border-top: -20px;
  box-sizing: content-box;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.botonAgregar{
  background-color: #fabb00;
  border: none;
  border-radius: 8px !important;
  font-size: 14px;
  height: 28px;
  padding-right: 10px;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/* .botonAgregar :hover{
  background-color: #fddb73;
} */

.textoBoton{
  font-family: 'Roboto', sans-serif !important;
  color: #093c5d;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.36px;
}

.iconoBoton{
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-left: 6px;
}

.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexDos{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.textoTitulo{
    color: #323232;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.61px;
  }
  
  .contenedorTabla{
    margin-top: 15px;
  }
  
  .contenedorTituloTabla{
    height: 32px;
    width: 100%;
    background-color: #e9ebf1;
  }
  
  .columnaTabla{
    padding: 0px 24px;
  }
  
  .columnaTabla > span{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #303030;
    font-size: 13px;
  }
  
 .columnTablaText > span{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #303030;
    font-size: 13px;
 }
 .columnTablaText{
     padding:  0px 24px !important;

 }
 .columnTablaTextContent > span{
    font-family: 'Roboto', sans-serif;
    color: #303030;
    font-size: 13px;
 }
 .columnTablaTextContent{
    padding:  0px 24px !important;

}
  
  .filaContenidoTabla{
    width: 100%;
    height: 58px;
    border-bottom: solid 1px #e9ebf1;
  }

  .filaContenidoTabla2{
    width: 100%;
    height: 40vh;
    border-bottom: solid 1px #e9ebf1;
  }
  
  .columnaUnoFlex{
    display: flex;
    align-items: center;
  }
  
  .contenedorDatos{
    margin-left: 18px;
  }
  
  .nombreAdmin{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: #222;
  }
  
  .correoAdmin{
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    opacity: 0.6;
    color: #222;
  }
  
  .contenedorTextoMenu{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .textoMenuEditar{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #222;
    opacity: 0.8;
    margin-right: 37px;
  }
  
  .iconoMenu{
    width: 11px;
    height: 11px;
  }
  
  .textoMenuEliminar{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #222;
    opacity: 0.8;
    margin-right: 19px;
  }
  
  .iconoMenuEliminar{
    width: 18px;
    height: 18px;
  }
  
  .contenedorUpdate{
    width: 579px;
  }
  
  .columnaSuperiorUno{
    width: 50%;
  
  }
  
  .columnaSuperiorDos{
    width: 50%;
  }
  
  .contenedorSuperior{
    display: flex;
  }
  
  .iconoUpdate{
    width: 37px;
    height: 37px;
    border-radius: 8px;
  }
  
  .contenedorColumnaUnoUpdate{
    display: flex;
    align-items: center;
  }
  
  .contenedorDatosUpdate{
    align-items: center;
    margin-left: 15px;
  }
  
  .tituloDatosUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #222;
  }
  
  .tituloNombreUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #323232;
    margin-top: 10px;
  }
  
  .inputUpdate{
    width: 217px;
    height: 20px;
    margin-top: -20px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    border: none;
  }
  
  .inputUpdate:focus{
    outline: none !important;
    border: solid 1px #fff;
  }
  
  .fieldsetUpdate{
    border: solid 1px #c7ccdc;
    border-radius: 4px;
    width: 217px;
    height: 27px;
  }
  
  .textoFieldsetUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 9px;
    color: #222;
  }
  
  .contenedorInputsUpdate{
    margin-top: 18px;
    margin-bottom: 16px;
  }
  
  .selectProyectos{
    width: 235px;
  }
  
  .contenedorPermisos{
    display: flex;
    justify-content: center;
    margin-top: 14px;
  }
  .tablaPermisos{
    width: 495px;
    height: 559px;
    background-color: #f5f6f9;
    border-radius: 12px;
  }
  
  .centrado{
    display: flex;
    justify-content: center
  }
  
  .acordionBase{
    width: 460px;
    height: 32px;
    border: none !important;
    box-shadow: none !important;
  }
  
  .accordionSeleccionado{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px rgba(250, 187, 0, 0.4);
  }
  
  .contenedorAcordion{
    justify-content: center;
    width: 460px;
    height: 32px;
    align-items: center;
    margin-top: 14px;
  }
  
  .contenedorTitulosAccordion{
    width: 460px;
    height: 32px;
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  
  .titulosAccordion{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 17px;
  }
  
  .contenedorIconoExpande{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
  
  .iconoExpande{
    width: 16px;
    height: 16px;
  }
  
  .iconoArriba{
    background-color: #fff;
    border-radius: 4px;
  }
  
  .iconoAbajo{
    background-color: #fabb00;
    border-radius: 4px;
    box-shadow: 0 3px 4px 0 rgba(250, 187, 0, 0.4);
  }
  
  .tituloAccordion{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: #222;
    font-weight: 500;
    opacity: 0.77;
  }
  
  .contenedorSwitch{
    margin-right: 34px;
  }
  
  .filaChecks{
    width: 463px;
    height: 32px;
    margin-left: -18px;
    margin-top: -4px;
  }
  
  .nivelDos{
    display: flex;
    align-items: center;
    background-color: #e9eaef;
  }
  
  .contenedorChecks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 463px;
    height: 32px;
  }
  
  .botonEliminar{
    background-color: #d31313 !important;
    padding: 15px 15px;
    margin-top: 20px;
    margin-right: 50px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgba(211, 19, 19, 0.4);
    font-size: 16px;
    color: #ffffff !important;
  }

  .dialogContainer {
      padding:15px;
  }

  .dialogFields {
      margin-bottom:24px !important;
  }

.centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
}

.checkboxCustom {
    color: #fabb00 !important;
}

/*Clases ventanas Eliminar*/

.em_container{
  width: 499px;
  height: auto;
  box-sizing: border-box;
}

.contenedorCerrarModal{
  width: 499px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 21.17px;
  box-sizing: border-box;
}

.cerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_modal.svg');
  width: 13.18px;
  height: 13.22px;
  margin-right: 24.33px;
  cursor: pointer;
}

.contenedorPrincipal{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-top: 16.75px;
}


.iconoEliminar{
  background-image: url('/src/assets/svg/icono_modal_eliminar.svg');
  width: 104.17px;
  height: 104.17px;
}

.em_titulo{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #093c5d;
  margin-top: 12.91px;
  margin-bottom: 19.5px;
}

.em_mensajePrtincipal{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #222222;
  opacity: 0.8;
  text-align: center;
  line-height: 20px;
}

.input{
  width: 187.52px;
  height: 32.09px;
  border: 0.8px solid #c7ccdc;
  margin-top: 6.41px;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  color: #222222;
  opacity: 0.8;
}

.em_contenedorBotones{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34.5px;
  margin-bottom: 34px;
}

.em_botonCancelar{
  width: 130px;
  height: 40px;
  border: 0.8px solid #e1e1e1;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #093c5d;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 25px;
}

.em_botonConfirmarActivo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: #FBE7E7;
  cursor: pointer;
}

.em_iconoConfirmarEliminar{
  background-image: url('/src/assets/svg/icono_confirmar_eliminar.svg');
  width: 13px;
  height: 17px;
  margin-right: 4.2px;
}

.em_botonConfirmarInactivo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: #FBE7E7;
  cursor: no-drop;
  opacity: 0.5;
}

.em_iconoConfirmarEliminar{
  background-image: url('/src/assets/svg/icono_confirmar_eliminar.svg');
  width: 13px;
  height: 17px;
  margin-right: 4.2px;
}

.w_80{
  width: 80% !important; 
}