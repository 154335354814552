.header{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #022338;
}

.contenedorLogo{
  margin-left: 77px;
}

.contenedorPrincipal{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #04314d;
  padding-bottom: 20px;
}

.contenedorInterno{
  width: 80%;
  height: 100%;
  min-height: 100vh;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}

.contenedorTexto{
  width: 80%;
  height: 100%;
}

.textotTitulo{
  font-family: 'Roboto',sans-serif;
  font-weight: 700;
  font-size: 25px;
}

.textoSubtitulo{
  font-family: 'Roboto',sans-serif;
  font-weight: 700;
  font-size: 20px;
}

.headerInterno{
  width: 80%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.logoUno{
  background-image: url('../../assets/svg/logo_its_avisoPrivacidad.svg');
  width: 300px;
  height: 50px;
  background-size: cover;
}

.logoDos{
  background-image: url('../../assets/svg/logo_bits_avisoProvacidad.svg');
  width: 100px;
  height: 50px;
  background-size: cover;
}

.tituloInterno{
  margin-top: 10px;
  margin-bottom: 15px;
}

.texto{
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
}

.negritas{
  font-weight: 700;
}

.link{
  color:rgb(0, 140, 187);
}

.segundaLinea{
  margin-top: 30px;
}
.inicioLista{
  margin-bottom: 20px;
}
.lista{
  margin-bottom: 10px;
  margin-left: 20px;
}

.listaDecoracion{
  text-decoration: overline;
  list-style-type: square;
}

.subrayado{
  text-decoration: underline;
}