@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.header-container, .beneficiary-form-container{
  background: white;
  border-radius: 25px;
  PADDING:25px;
}

.header-title{
  font-weight: bold;
}

.primary-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px !important;
  cursor: pointer;
  font-family: 'Roboto',sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #093c5d;
}

.table{
  width: 100%;
  border-collapse: collapse;
}
.table-header{
  background-color: #e9ebf1;
  height: 32px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030
  
}
.table-title{
  width: auto
}

.table-row{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  height: 40.81px;
  border-bottom: 1px solid #C7CCDC;
}