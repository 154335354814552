.Notification__field span{
    display: block;
}

.Notification__field span:first-child{
    color:#222;
    font-weight: bold;
    opacity: 1;
}

.Notification__field span,.Modal__Notification__text{
    color:#222;
    opacity: 0.6;
}

.sheduledDate__Question{
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
    margin-top: 10px;
    margin-left: 10px;
}

.warning__date{
    margin: 10px 85px 11px 0;
    opacity: 0.8;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
}

.grid__projects{
    border: 1px solid #c7ccdc;
    border-radius: 8px;
    margin: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid__projects span{
    margin: 8px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
}

.seletion__projects{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
    padding-left: 20px;
}

.projects__list{
    margin: 0;
    padding: 0;
}

.projects__list li{
    list-style: none;
    text-decoration: none !important;
}

.more__projects{
    background: #f1f2f5;
    border-radius: 10px;
    padding: 2px 6px;
    border: solid 1px #c7ccdc;
    cursor: pointer;
}
.grid__projects .span-notification{
    word-wrap: break-word;
}

.grid__projects .span-notification input{
   vertical-align:middle;
   width:30px !important;
}
.grid__projects span{
    margin: 8px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
}

.btnSave{
    width: 140px;
    height: 40px;
    background-color: #FABB00;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #093C5D;
    cursor: pointer;
}

.btnCancel{
    width: 140px;
    height: 40px;
    background-color: #ffffff;
    border: 0.8px solid #E1E1E1;
    border-radius: 8px;
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    color: #093C5D;
    cursor: pointer;
    margin-right: 10px;
}