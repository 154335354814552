@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.contenedorTitulo{
  width: 520px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  line-height: 28px;
  color: #093C5D;
  display: flex;
  justify-content: center;
}

.contenedorCerrarModal{
  width: 5%;
  display: flex;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
}

.tituloCrearEmpresa{
  width: 80%;
  margin-top: 20px;
}

.iconoCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
  width: 13.18px;
  height: 13.18px;
  cursor: pointer;
}

.iconoCerrarModal:hover{
  filter: opacity(0.4) drop-shadow(0 0 0 red); 
}


.contenedorTabs{
  width: 520px !important;
  height: auto !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 20px !important;
}

.tabs{
  width: 420px !important;
  height: 32px !important;
  background-color: #F1F2F5 !important;
  border-radius: 4px !important;
  display: flex !important;
}

.tab{
  width: 33.3% !important;
  max-width: 33.3% !important;
  min-width: 33.3% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  text-transform: none !important;
}

.MuiTabs-root{
  display: block;
}

.MuiTab-root.tabCompletaSeleccionado{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  text-align: center !important;
  background-color: #093C5D !important;
  color: #ffffff !important;
}

.MuiTab-root.tabCompletaSinSeleccion{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  text-align: center !important;
  background-color: #093C5D !important;
  color: #ffffff !important;
}

.MuiTab-root.tabSinSeleccionSinTerminar{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  text-align: center !important;
  color: #303030 !important;
  opacity: 0.6 !important;
}

.tabCompletaSeleccionado{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  text-align: center !important;
  background-color: #093C5D !important;
  color: #ffffff !important;
}

.tabCompletaSinSeleccion{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  text-align: center !important;
  background-color: #093C5D !important;
  color: #ffffff !important;
}

.tabSinSeleccionSinTerminar{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  text-align: center !important;
  color: #303030 !important;
  opacity: 0.6 !important;
}

.circuloCompletoSeleccionado{
  width: 14px !important;
  height: 14px !important;
  background-color: #11CA73 !important;
  border-radius: 50% !important;
  margin-left: 5.5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}


.circuloSinSeleccionSinTerminar{
  width: 14px !important;
  height: 14px !important;
  background-color: #C7CCDC !important;
  border-radius: 50% !important;
  margin-left: 5.5px !important;
}

.tabSeleccionadoSinTerminar{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  color: #093C5D !important;
  box-shadow: inset 0 -2px 0 #C7CCDC !important;
}



.iconoCompleto{
  background-image: url('/src/assets/svg/icono_tab_completo.svg');
  width: 8px;
  height: 5px;
}