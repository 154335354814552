.containerCardPrestacion{
    display: flex;
    flex-direction: row;
    border-radius: 10px !important;
    justify-content: flex-start;    
    width: 550px;
    height:220px;        
  }

 .contedorGeneralPrestacion{
  padding: 0px !important;
  position: relative;
  display: flex;
  justify-content: flex-start  ;
 } 
 
 .containerImagenPrestacion{
  width: 200px;
  height: 210px; 
 }
 .imgPrestacion{
    width:200px; 
    height:220px;
  }
.containertipoPrestacion{
  left: 0px;
  top:0px;
  position:absolute;
  background-color: #fabb00;
  border: none;    
  font-weight: 700;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  height: 25px;
  width: 155px;
  color: rgb(9, 60, 93);    
  border-bottom-right-radius: 10px;
}
.tipoPrestacion{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.infoCardPrestacion{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:100% ;    
}

.superiorPrestacion{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    width:100% ; 
 }
  .containertitlePrestacion{
    margin-left: 15px;
    width:160px ;    
    margin-bottom: 5px;
  }

  .titlePrestacionCard{
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    font-size: 17px;
    color:#093C5D ;
    width:100% ;
}

.switchPrestacion{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left:  35px;   
}

.actPrestacion{
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  font-size: 13px;
  color: #303030;  
 
}

.crudPrestacion{
   margin-left: 15px;     
}

.containerDescripcionPrestacion{
    width: 90%;
    height: 38px;    
    margin-top:  5px;
    margin-bottom:  20px;
    margin-left: 20px;

}
.descripcionPrestacion{
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 12px;
  color: #252525; 
}
.star{
    margin-left: 20px;
    width: 100%;
    height: 30px; 
    margin-top: 10px;
}

.countersPrestacion{    
    display: flex;
    flex-direction: row;    
    justify-content: space-evenly;   
    margin-top: 5px;
}

.containerPrestacion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}

.countPrestacion{
  font-family: "Roboto", sans-serif !important;  
  font-weight: 600; 
  color: #2186C6; 
  font-weight: 600;
  font-size: 32px;
  width: 55px;
  height: 20px;
  margin-left: 10px;
}

.meensajePrestacion{
  font-family: "Roboto", sans-serif !important;  
  margin-top: 15px;
  font-weight: 500; 
  font-size: 12px;   
  color: #222222; 
}

.v-line{
  border-left: 1px solid #093C5D;
  height:15px;    
 }
 .pv-line{
  border-left: 1px solid #093C5D;
  height:45px;    
 }


