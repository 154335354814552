.tablePrestacion{
    width: 1700px; 
}

.btnViewInfoPrestacion{
    background-color: #FABB00 !important;
    color:#093C5D !important;
}

.btnViewInfoPrestacion:hover{
    background-color: #FABB00 !important;
}

.cellSpanPrincipalPrestacion{
    background-color: #F5F6F9;
    height: 32px;
    width: 10px;
}

.tableImgPrincipalPrestacion{
    background-color: #F5F6F9;
    height: 32px;  
    width: 10px;
}

.tableTitlePrincipalPrestacion{
    background-color: #F5F6F9;
    height: 32px;  
    width: 200px;
}
.tableActivaPrincipalPrestacion{
    background-color: #F5F6F9;
    width: 40px;
}

.tableMenuPrincipalPrestacion{
    background-color: #F5F6F9;
    width: 40px;
}

.TableheaderPrincipalPrestacion{
    background-color: #F5F6F9;
    height: 32px;
}

.headerSecondTablePrestacion{
    height: 32px;
    border-radius: 10px !important;
}

.tableAreaSecondTablePrestacion{
    width: 150px;
}

.tableAreaTablePrestacion{
    width:200px; 
    word-break: break-all;
}
@media(min-width:1440px){
    .tableAreaTablePrestacion{
        width:auto; 
        word-break: normal;
    }
  }

.tableCountSecondTablePrestacion{
 font-weight: 600;
 font-size: 13px;
 color:#2186C6;
 text-decoration: underline #2186C6;  
}