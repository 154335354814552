@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.contenedorPrincipal {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 8px;
  margin-left: -25px;
  border-top: -20px;
  box-sizing: content-box;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.botonAgregar {
  background-color: #fabb00;
  border: none;
  border-radius: 8px !important;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  padding-right: 10px;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/* .botonAgregar :hover{
  background-color: #fddb73;
} */

.textoBoton {
  font-family: "Roboto", sans-serif !important;
  color: #093c5d;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.36px;
}

.iconoBoton {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-left: 6px;
  color: #093c5d;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textoTitulo {
  color: #323232;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.61px;
}

.contenedorTabla {
  margin-top: 15px;
}

.contenedorTituloTabla {
  height: 32px;
  width: 100%;
  background-color: #e9ebf1;
}

.columnaUnoTabla {
  padding-left: 24px;
}

.columnaUnoTabla > span {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #303030;
  font-size: 13px;
}

.columnaDosTabla {
  padding-right: 15px;
}

.columnaDosTabla > span {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #303030;
  font-size: 13px;
}

.imagenColaborador {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.filaContenidoTabla {
  width: 100%;
  height: 58px;
  border-bottom: solid 1px #e9ebf1;
}

.columnaUnoFlex {
  display: flex;
  align-items: center;
}

.contenedorDatos {
  margin-left: 18px;
}

.nombreAdmin {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #222;
}

.correoAdmin {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  opacity: 0.6;
  color: #222;
}

.contenedorTextoMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textoMenuEditar {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 37px;
}

.iconoMenu {
  width: 11px;
  height: 11px;
}

.textoMenuEliminar {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 19px;
}

.iconoMenuEliminar {
  width: 18px;
  height: 18px;
}

.contenedorUpdate {
  width: 579px;
}

.columnaSuperiorUno {
  width: 50%;
}

.columnaSuperiorDos {
  width: 50%;
}

.contenedorSuperior {
  display: flex;
}

.iconoUpdate {
  width: 37px;
  height: 37px;
  border-radius: 8px;
}

.contenedorColumnaUnoUpdate {
  display: flex;
  align-items: center;
}

.contenedorDatosUpdate {
  align-items: center;
  margin-left: 15px;
}

.tituloDatosUpdate {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.tituloNombreUpdate {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #323232;
}

.inputUpdate {
  width: 217px;
  height: 20px;
  margin-top: -20px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  border: none;
}

.inputUpdate:focus {
  outline: none !important;
  border: solid 1px #fff;
}

.fieldsetUpdate {
  border: solid 1px #c7ccdc;
  border-radius: 4px;
  width: 217px;
  height: 27px;
}

.textoFieldsetUpdate {
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  color: #222;
}

.contenedorInputsUpdate {
  margin-top: 18px;
  margin-bottom: 16px;
}

.selectProyectos {
  width: 235px;
}

.contenedorPermisos {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.tablaPermisos {
  /* border: 1px solid blue; */
  width: 495px;
  height: 559px;
  background-color: #f5f6f9;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: scroll;
  /* align-items: flex-start; */
}

/* .centrado {
  display: flex;
  justify-content: center;
} */

.acordionBase {
  /* border: 1px solid red !important; */
  /* width: 460px;
  height: 32px; */
  border: none !important;
  box-shadow: none !important;
}

.accordionSeleccionado {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(250, 187, 0, 0.4);
}

.contenedorAcordion {
  justify-content: center;
  width: 460px;
  height: 32px;
  align-items: center;
  margin-top: 14px;
}

.contenedorTitulosAccordion {
  width: 460px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.titulosAccordion {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-left: 17px;
}

.contenedorIconoExpande {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.iconoExpande {
  width: 16px;
  height: 16px;
}

.iconoArriba {
  background-color: #fff;
  border-radius: 4px;
}

.iconoAbajo {
  background-color: #fabb00;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(250, 187, 0, 0.4);
}

.tituloAccordion {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #222;
  font-weight: 500;
  opacity: 0.77;
}
.subTituloAccordion {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  opacity: 0.77;
  margin: 0 10px 0 10px;
}
.subTituloAccordion2 {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  opacity: 0.77;
  margin: 0 5px 0 0px;
}


.contenedorSwitch {
  margin-right: 34px;
}

.filaChecks {
  width: 463px;
  height: 32px;
  margin-left: -18px;
  margin-top: -4px;
}

.nivelDos {
  display: flex;
  align-items: center;
  background-color: #093c5d;
  opacity: 0.5;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 5px;
}

.contenedorChecks {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 463px;
  height: 32px;
}

.divAux {
  border: 1px solid red;
  height: 200px;
}

.nivelDosMenu {
  width: 450px;
  margin-left: 20px;
}

.nivelTresMenu {
  width: 440px;
  margin-left: 30px;
}

.NivelCuatroMenu {
  width: 430px;
  margin-left: 40px;
}

.nivelTresChecks {
  width: 410px;
  margin-left: 40px;
}

.nivelCuatroChecks {
  width: 390px;
  margin-left: 60px;
}

.BackgrounNivelDos {
  background-color: #f5f6f9;
}
.BackgrounNivelTres {
  background-color: rgba(9, 60, 93, 0.15);
}

/* icono cerrar modal */
.contenedorCerrarModal{
  width: 5%;
  margin-top: -11%;
  margin-left: 90%;
  align-items: center;
}

.iconoCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
  width: 13.18px;
  height: 13.18px;
  cursor: pointer;
  margin-bottom: auto;
  margin-left: auto;
  transition: 0.5s;
}

.iconoCerrarModal:hover{
  transition: 0.5s;
  filter: opacity(0.4) drop-shadow(0 0 0 red); 
}