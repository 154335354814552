.contenedorHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}

.squareView{
  cursor: pointer;
}

.squareList{
  cursor: pointer;
}

.conteinderBtnsPrestaciones{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25%;      
    margin-top: 24px;
    margin-right: 40px;
    margin-bottom: 16px;
}

.btnNuevaPrestacion{
     background-color: #fabb00;
    padding: 15px 15px;   
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px !important;
    height: 40px;
    width: auto;
    font-size: 14px;
    color: rgb(9, 60, 93);
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}
.btnNuevaPrestacion:active{
    background-color: #fddb73;
}
.alineacionBoton{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;  
}
.containerBodyPrestacion{
      border-radius: 20px !important;
      background-color: white;    
      align-items: center;
      margin-left: 40px;
      width: 96%; 
      height: auto;  
  }

 .exCont{
  display: flex;
  flex-direction: row;  
  justify-content:start ; 
  flex-wrap: wrap;
  gap: 20px;
 
 } 

.nombrePrestacion{
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
}
.containerBtnFindFilter{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center; 
    margin-bottom: 20px; 
}

.containerBtnFiltrar{
    margin-right: 20px;
  }
   
  .btnFiltrar{
    font-family: "Roboto", sans-serif !important;
    background-color: #FFFFFF;
    padding: 15px 15px;   
    border-color: #C7CCDC;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px !important;
    height: 36px;
    width: 100px;
    font-size: 14px;
    color: rgb(9, 60, 93);    
  }

  
  /*menu crud*/
.contenedorTextoMenuPrestacion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imgDeletePres{
  margin-left: 20px;
}

.textoMenuEliminarPrestacion{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 19px;
}
.iconoMenuEliminarPrestacion{
  width: 18px;
  height: 18px;
}

.menuListNewPrestacion{
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.menuListOptionNewPrestacion{
  padding: 0px !important;
}
.moreOptionNewPrestacion {
  color: #fabb00;
  font-size: 35px !important;
  width: 30px !important;
  height: 30px !important;
}
.textoMenuEditarPrestacion{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 37px;
}
.imgViewPres{
  margin-left:-22x;
}

.iconoMenuViewPrestacion{
  color: #2186C6 !important;
  margin-left: -25px;
} 

.textoMenuEditarPres{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #222;
  opacity: 0.8;
  margin-right: 37px;
}
  
.iconoMenuPres{
  width: 11px;
  height: 11px;
}
.imgEditaPres{
  margin-left: 20px;
}

