

/*Header*/
.contenedorHeader{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.botonDispersar{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fabb00;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(250, 187, 0, 0.4);
  margin-left: 24px;
  width: 115px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #093c5d;
  height: 32px;
  cursor: pointer;
}

.botonDispersarInactivo{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fabb00;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(250, 187, 0, 0.4);
  margin-left: 24px;
  width: 115px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #093c5d;
  height: 32px;
  cursor: pointer;
  opacity: 0.6;
}

.botonDispersar:active{
  background-color: #fddb73;
}

.iconoDispersar{
  background-image: url('/src/assets/svg/icono_dispersar.svg');
  width: 24.06px;
  height: 16px;
  margin-right: 6.19px;
}

.selectEmpresa{
  width: 340px;
  height: 40px;
  padding-left: 17px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  line-height: 15.23px;
  color: #303030;
  font-size: 13px;
  border: 1px solid #c7ccdc !important;
  border-radius: 4px;
  background-color: #ffffff;
}


.selectEmpresa::-ms-expand{
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #093C5D;
  transform: rotate(90deg);
  font-weight: bolder;
  font-size: 13px;
  cursor: pointer;
  padding-right: 17px;
}

/*Contenedor Central*/
.contenedorCentral{
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 26px;
  border-radius: 12px;
  padding-top: 32px;
  padding-right: 26px;
  box-sizing: border-box;
  padding-bottom: 25px;
}
/*Titulos*/
.contenedorTitulos{
  padding-left: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.titulo{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 24px;
  line-height: 28.13px;
  letter-spacing: 0.67px;
  color: #323232;
}

.contenedorSaldos{
  display: flex;
  justify-content: center;
  align-items: center;
}


.contenedorSaldoDisponible{
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
}

.tituloSaldo{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.44px;
  color: #303030;
}

.contenedorInternoSaldo{
  width: 160px;
  height: 25px;
  background-color: #e9ebf1;
  border: 1px solid #c7ccdc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;  
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  margin-top: 3px;
}

.contenedorTotales{
  width: 432px;
  height: 60px;
  background-color: #f5f6f9;
  border-radius: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 28px;
}

.contenedorTotales > div{
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tituloTotales{
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.31px;
  color: #303030;
  opacity: 0.6;
}

.datoTotales{
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  margin-top: 4px;
}

/*Formulario*/

.contenedorFormulario{
  width: 100%;
  height: auto;
}

.filaFormulario{
  width: 100%;
  display: flex;
  gap: 46px;
  justify-content: center;
  margin-top: 10px;
}
.filaTresFormulario{
  width:510px;
}

.inputFormulario{
  width: 495.32px; 
  height: 32px;
  border: 1px solid #c7ccdc;
  border-radius: 4px;
  padding-left: 12px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
}

.selectFormulario{
  width: 511.33px;
  height: 36px;
  border: 1px solid #c7ccdc !important;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 12px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
}

.segundoInput{
  margin-top: 21px;
}

.contenedorBoton{
  width: 100%;
  margin-top: 18.7px; 
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.iconoGuardar{
  background-image: url('../../assets/svg/icono_guardar_sede.svg');
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

/*Tabla*/
.tabla{
  width: 98%;
  margin-left: 27px;
  border-collapse:collapse;
  margin-top: 29px;
}

.tituloTabla{
  background-color: #e9ebf1;
  height: 32px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #303030;
}


.datosTabla{
  height: 38px;
  border-bottom: solid 1px #c7ccdc;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.8;
}

/*checkbox*/
.checkboxitem {
  display: inline-block;
  position: relative;
  text-align: left;


}

.checkboxitem input[type="checkbox"]:checked + label:after {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.checkboxitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border: 1px solid #c7ccdc;
  background-color: transparent;
}

.checkboxitem input[type="checkbox"] {
  display: none;
}


.pd_primerColumnaTitulo{
  display: flex;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
}

.contenedorError{
  height: 15px;
}

.spanError{
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #d31313;  
}

.loading{
  background-image: url('../../assets/loading.gif');
  width:  400px;
  height: 400px;
  background-size: cover;
}

.contenedorLoading{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedorBotonEliminar{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d31313;
  cursor: pointer;
}

.iconoEliminarInterno{
  background-image: url('../../assets/svg/icono_eliminar_datos.svg');
  width: 10px;
  height: 14px;
  margin-right: 5px;
}

.pd_botonEstatus{
  width: 113px;
  height: 24px;
  border-radius: 8px;
  cursor: pointer;
}

.pd_botonEstatusCuatro{
  display: flex;
  align-items: center;
  background-color: #11CA73;
}

.pd_textoBotonEstatusCuatro{
  font-family: 'Roboto', sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #ffffff;
  margin-left: 35px;
}

.pd_iconoEstatusCuatro{
  background-image: url('/src/assets/svg/icono_palominta.svg');
  width: 9.5px;
  height: 8px;
  margin-left: 16px;
}



.pd_botonEstatus{
  width: 113px;
  height: 24px;
  border-radius: 8px;
  cursor: pointer;
}

.pd_botonEstatusUno{
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.25px;
  border: 1px solid #c7ccdc;
}

.pd_botonEstatusDos{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8ea64;
}

.pd_textoBotonEstatusDos{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #093c5d;
  font-size: 9px;
}

.pd_iconoDispersarDos{
  background-image: url('/src/assets/svg/icono_dispersar.svg');
  width: 24.06px;
  height: 16px;
  margin-right: 6.19px;
}


.pd_contEstatusTres{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pd_botonEstatusTres{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 113px;
  height: 24px;
}

.pd_textoBotonErrorDos{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #303030;
  cursor: pointer;
}

.pd_textoBotonError{
 font-family: 'Roboto', sans-serif;
 font-weight: 500;
 font-size: 9px;
 line-height: 11px;
 letter-spacing: 0.25px;
 color: #d31313;
 opacity: 0.8;
 margin-bottom: 1px;
}

.pd_iconoIntentar{
  background-image: url('/src/assets/svg/icono_intentar.svg');
  width: 10px;
  height: 12.01px;
  margin-bottom: 1px;
}
.pd_contenedorEstatusTres{
  margin-right: 8px;
}

.pd_botonEstatusCuatro{
  display: flex;
  align-items: center;
  background-color: #11CA73;
}

.pd_textoBotonEstatusCuatro{
  font-family: 'Roboto', sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #ffffff;
  margin-left: 35px;
}

.pd_iconoEstatusCuatro{
  background-image: url('/src/assets/svg/icono_palominta.svg');
  width: 9.5px;
  height: 8px;
  margin-left: 16px;
}