.conteiner{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.titleText{
    padding-left: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #222222;
}

.conteinerTitleText{
    margin-bottom: 20px;
}

.conteinerText{
    margin-bottom: 10px;
}

.text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #222222;
    text-align: justify;
}

.divC{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

