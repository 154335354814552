.collaborator__container {
    background-color: #fff;
    margin: 0 30px;
}

.lineapapu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
}

.MuiList-root .MuiMenu-list .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}

.form-dialog-title-class {
    font-size: 16px !important;
    margin-left: 0px !important;
}