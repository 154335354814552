@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.mainBox {
  display: flex;
  justify-content: space-between;
}

.mainBoxCollaborator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainActionsCollaboratorContainer {
  display: flex;
  gap: 10px;
  height: 36px;
}

.mainActionsContainer {
  display: flex;
  gap: 10px;
}

.paper {
  background: white;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 12px 12px 0 0;
}

.campaignInfo {
  margin-bottom: 5px;
  padding: 0 16px;
}

.campaignTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: #838383;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.slash {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #323232;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 5px;
}

.collaborator {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #323232;
}

.period {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #838383;
  padding: 0 16px;
}

.tableContainer {
  flex-grow: 1;
}

.filterContainer {
  width: 100%;
}

.noObjectives {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #303030;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.noObjectivesContainer {
  width: 100%;
}

.noObjectivesText {
  font-family: 'Roboto', sans-serif;
}

.twoInputsContainer {
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.selectLabel {
  background-color: white;
  font-style: normal;
}

.select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-style: normal;
}

.selectCollaboratorsButton {
  background-color: #9DB1BE;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 6px;
  text-align: left;
  font-style: italic;
  cursor: pointer;
}

.createOptionsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.secondaryButton {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  gap: 6px;
  border: 1px solid #c7ccdc;
  height: 40px;
  color: #093c5d;
  background: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  width: 175px;
  cursor: pointer;
}

.secondarySmallButton {
  border-radius: 6px;
  gap: 6px;
  border: 1px solid #c7ccdc;
  height: 36px;
  color: #093c5d;
  background: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  width: 89px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collaboratorsActionsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.collaboratorCloseButton {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  gap: 6px;
  border: 1px solid #c7ccdc;
  height: 36px;
  color: #093c5d;
  background: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  cursor: pointer;
}

.collaboratorSaveButton {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  border: none;
  gap: 6px;
  height: 36px;
  color: #093c5d;
  background: #fabb00;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  cursor: pointer;
}

.evidenceTable {
  width: 100%;
  border-collapse: collapse;
}

.evidenceTableHead {
  background: #E9EBF1;
  height: 32px;
  color: #303030;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
}

.evidenceTableBody {
  height: 32px;
  color: #303030;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}

.evidenceTr {
  height: 32px;
  color: #303030;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}

.evidenceTableBodyRow {
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #C7CCDC;
  padding: 10px;
  text-align: center;
}

.evidenceTableBodyRowIcon {
  border-bottom: 1px solid #C7CCDC;
  padding: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.evidenceTable tr:last-child td {
  border-bottom: none;
}

.evidenceTableImage {
  width: 20px;
  height: 26.4px;
}

.evidenceActions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 8px 20% !important;
}

.progress {
  color: #093C5D;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
}

.evidenceCancelContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.evidenceCancelButton {
  border-radius: 6px;
  border: 1px solid #c7ccdc;
  height: 36px;
  color: #093c5d;
  background: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  width: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.containerInfoCampaign{
  display: flex;
  justify-content: start;  
  gap: 10px;
}

.infoCampaign {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #838383;
  padding: 0 16px;
}