.contenedorSaldos{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contenedorHeader{
  display: flex;
  align-items: center;
}

.saldos{
  min-width: 418px;
  width: auto;
  height: 86px;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconopdf{
  background-image: url('../../assets/svg/icono_pdf.svg');
  width: 20px;
  height: 25px;
}
.saldosCargando{
  min-width: 418px;
  width: auto;
  height: 86px;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saldos > div{
  width: auto;
  height: 100%;
  display: flex;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tituloSaldos{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.36px;
}

.saldoInterno{
  background-color: #E9EBF1;
  opacity: 0.6;
  border: 1px solid #C7CCDC;
  border-radius: 8px; 
  width: 155px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
}

.nombreEmpresaSaldos{
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.44px;
  border: none;
  width: auto;
  height: 25px;
  padding-left: 16.55px;
  background-color: #ffffff;
}

.nombreEmpresaSaldos::-ms-expand{
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: solid 1px red;
  border-radius: 4px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #093C5D;
  transform: rotate(90deg);
  font-weight: bolder;
  font-size: 13px;
  cursor: pointer;

}

.contenedorUnoSaldo{
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.contenedorTitulo{
  width: 100%;
  height: auto;
  margin-top: -10px;
  font-family: 'Roboto';
  font-weight: 700;
  color: #323232;
  line-height: 28.13px;
  font-size: 24px;
  letter-spacing: 0.67px;
}

.contenedorPrincipal{
  width: 100%;
  height: 600px;
  margin-top: 28px;
  min-width: 1202px;
  max-width: 1900px;
}

.seleccion{
  width: 100%;
  height: 125px;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  margin-bottom: 19px;
}

.seleccionInternoUno{
  width: 20%;
  height: 100%;
  padding-top: 23px;
  padding-left: 35px;
  box-sizing: border-box;
}

.seleccionInternoDos{
  width: 20%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.seleccionInternoTres{
  width: 65%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.seleccionInternoCuatro{
  width: 15%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}

.tituloInternoUno{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  margin-bottom: 6.5px;
}

.textoInternoUno{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #838383;
}

.margenTextoInternoUno{
  margin-bottom: 6.5px;
}

.contenedorProceso{
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.6;
}

.contenedorProcesoLinea{
  width: 80%;
  display: flex;
  align-items: center;
}

.circuloProceso{
  width: 18px !important;
  height: 18px !important;
  background-color: #c7ccdc;
  border-radius: 50%;
}

.lineaProceso{
  width: 40%;
  height: 2px;
  background-color: #c7ccdc;
}

.contenedorInternoDatos{
  width: 100%;
  height: 78px;
  background-color: #f1f2f5;
  border-radius: 8px;
  display: flex;
  box-sizing: content-box;
}

.contenedorInternoDatosUno{
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: content-box;
  padding-left: 3%;
}

.tituloContenedorDatos{
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  font-weight: 400;
  color: #303030;
  opacity: 0.8;
}
.textoContenedorDatos{
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  font-weight: 500;
  color: #303030;
  opacity: 0.8;
}
.iconoEliminarInterno{
  background-image: url('../../assets/svg/icono_eliminar_datos.svg');
  width: 10px;
  height: 14px;
  margin-right: 5px;
}

.contenedorBotonEliminar{
  display: flex;
  align-items: center;
  justify-content: center;
}

.botonContinuaInterno{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 28px;
  background-color: #FABB00;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #093C5D;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
}

.contenedorBotonEliminar{
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.36px;
  color: #e0544b;
  cursor: pointer;
}

.contenedorSaldoInterno{
  width: auto;
  height: auto;
  box-sizing: content-box;
}

.optionEmpresa{
  height: 50px !important;
  font-size: 13px;
  padding: 16px;
}

.loading{
  background-image: url('../../assets/loading.gif');
  width: 300px;
  height: 300px;
  background-size: cover;
}

.loadingSaldo{
  background-image: url('../../assets/loading.gif');
  width: 80px;
  height: 80px;
  background-size: cover;
}

.contenedorFiltro{
  width: 400px;
  min-width: 400px;
  height: 40px;
  border: 1px solid #c7ccdc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.filtro{
  width: 90%;
  height: 35px;
  border:none;
  padding-left: 17px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
  border-radius: 8px;
}

.filtro::placeholder{
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #303030;
  opacity: 0.6;
}

.filtro:focus{
  border: none !important;
  outline: none;
}


.filtro:active{
  border: none !important;
  outline: none;
}

.iconoBuscador{
  background-image: url('/src/assets/svg/icono_buscar_dos.svg');
  width: 18.12px;
  height: 18.12px;
  margin-right: 10px;
}

.pp_contenedorIconoEliminar{
  margin-top: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.27;
  color: #E0544B;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 24px;
}

.pp_textoEliminar{
  margin-left: 5.36px;
}

.pp_iconoEliminar{
  width: 9.33px;
  height: 12px;
}

.select{
  width: auto;
  min-width: 400px;
  height: 42px;
  border: 1px solid #c7ccdc !important;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
  padding-left: 17px;
  margin-left: 46px;
}


.pc_tituloContenedorPrincipal{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin-left: 26px;
}

.p_contenedorTablaDispersion{
  margin-top: 26px;
  width: 33%;
}

.divGraphicConteiner{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.ivGraphic{
  height: 390px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(9, 60, 93, 0.2);
  border-radius: 12px;
}

.alineacionTituloGraficas{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.171429px;
  color: #093C5D;
  text-align: center;
}

.textGraphic{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.444444px;
  color: #303030;
  mix-blend-mode: normal;
  opacity: 0.6;
}

.textNumberGraphicBlue{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.444444px;
  color: #2186C6;
  margin: 0px;
}