header{
    height: 30px;
    background-color: #022338;
    height: 74px;
    box-sizing: border-box;
}

#IconNotification {
  color: white !important;
}

.colum {
  height: 42px;
  width: 90px;
  margin: 10px;
}

.UserButton span {
  color: white !important;
  margin-left: 10px !important;
  text-transform: capitalize;
}

.OutOnboardingButton {
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 31px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #093c5d;
  margin-left: 10px;
  margin-bottom: 9px;
  cursor: pointer;
}

.help{
  content: url("../..//assets/svg/icono-config-ayuda.svg");
}
