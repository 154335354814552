.container{
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
}

.divContract{
    height: 100%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 31.78px;
    padding-bottom: 80px;
    display: flex;
}

.divGenerateContract{
    width: 50%;
    
}

.titleContract{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.666667px;
    color: #323232;
    margin-bottom: 30px;
}

.contenedorFieldset{
    margin-bottom: 27.65px;
}

.contenedorDatos{
    margin-top: 27.65px;
    margin-bottom: 27.65px;
}

.titleData{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.444444px;
    color: #303030;
    background: #E9EBF1;
    border: 1px solid #C7CCDC;
    border-radius: 8px;
    text-align: center;
    width: 368px;
    height: 28.16px;
    
}

.titleData p{
    margin-top: 5px;
    margin-bottom: 0px;
}

.divText{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.361111px;
    color: #303030;
    margin:0px;
    border-bottom: 1px solid #C7CCDC;
    width: 368px;
    display: flex;
}

.doc{
    width: 100%;
    height: 700px;
    background: #F1F2F5;
    border-radius: 8px;
    overflow: scroll;
}

.botonAgregar{
    width: 270px;
    height: 40px;
    background-color: #FABB00;
    cursor: pointer;
    border: none;
    border-radius: 8px !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.iconoAgegar{
    background-image: url('/src/assets/svg/icono_prefactura.svg');
    width: 20px;
    height: 10px;
    margin-right: 6.5px;
    filter: opacity(0.4) drop-shadow(0,0,0 red);
    background-repeat: no-repeat;
}

.textoBotonAgregar{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.36px;
    color: #093C5D;
}

.dialogContainer {
    padding:15px;
}

.containerTitle{
    display: flex;
}
  
.centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
    text-align: center;
}

.contenedorCerrarModal{
    width: 5%;
    display: flex;
    margin-top: 0px;
    align-items: center;
}

.iconoCerrarModal{
    background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
    width: 13.18px;
    height: 13.18px;
    cursor: pointer;
    margin-bottom: auto;
    margin-left: auto;
  }
  
.iconoCerrarModal:hover{
filter: opacity(0.4) drop-shadow(0 0 0 red); 
}

.dialogoContento 
{
  max-width: 100%!Important;
}

.ColumnaEditarPuesto
{
  margin-left: 48px;
  margin-right: 48px;
}

.titulos{
    font-weight: bold;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 80%;
}

.pc_contenedorTabla{
    width: 98%;
    height: auto;
    overflow-x: auto;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
}

.pc_tablaPrincipal{
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
}

.pd_contenedorTitulos{
    background-color: #e9ebf1;
    height: 32px;
    border-radius: 10px !important;
}

.pc_columnaTitulos{
    min-width: 150px;
    margin-right: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #303030;
    line-height: 15px;
    letter-spacing: 0.30px;
}

.pc_columnaDatos{
    background-color: #fff ;
    border-bottom: solid 1px #c7ccdc;
    font-family: 'Roboto', sans-serif;
    color: #303030;
    opacity: 0.8;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    height: 37px;
}

.tableDivConcept{
    width: 150px;
}

.tableDivConcept p{
    text-overflow: ellipsis;
    overflow: hidden;
}

.containerOptions{
    display: flex;
    width: 80%;
}

.botonCancelar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 129px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    color: #093c5d;
    font-weight: bold;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.botonrRegresar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 24px;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    color: #093c5d;
    font-weight: bold;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;

    background-image: url('/src/assets/svg/returnRenew.svg');
    background-repeat: no-repeat;
    background-position: 5px center;

}

.botonrRegresar p{
    margin-left: auto;
    margin-right: 10px;
}

.textUsesData{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.361111px;
    color: #303030;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-right: auto;
}

.botonDescargar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    background-color: #fff;
    cursor: pointer;
    margin-left: auto;
    margin-top: 11px;

    background-repeat: no-repeat;
    background-position: 20px center;
    background-image: url('/src/assets/svg/iconoDownload.svg');

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.361111px;
    color: #093C5D;
    
}

.botonDescargar p{
    margin-left: auto;
    margin-right: 20px;
}

.divCollaborator{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    padding: 10px;
    z-index: 2;
    width: 349px;
}

.divCollaborator p {
    cursor: pointer;
}

.inputFecha {
    border-radius: 4px;
    border: solid 1px #c7ccdc;
    width: 110px;
    height: 20px;
    margin-top: 10px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.361111px;
    color: #303030;
    mix-blend-mode: normal;
    opacity: 0.8;

  }

  .inputFecha:focus {
    border: solid 1px #093c5d;
  }