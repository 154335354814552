
.botonAgregar{
  background-color: #fabb00;
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 180px  ; 
  background-image: url('/src/assets/svg/plusCalendar.svg');
  background-repeat: no-repeat;
  background-position-x: 20px;
  background-position-y: 12.5px;
  cursor: pointer;
}

.botonAgregar:active{
  transition: 0.9ms;
  background-color: #fddb73;
}
  
  .textoBoton{
    font-family: 'Roboto', sans-serif !important;
    color: #093c5d;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.36px;
  }

  .dialogContainer {
    padding:15px;
}


.dialogFields {
    margin-bottom:24px !important;
}

.centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
}

.endContainer{
    display: flex;
    align-content: center;
    justify-content: flex-end !important;
}

  .flex{
    display: flex;
    justify-content: center;
  }
    
  .tituloDatosUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #222;
  }
  .tituloNombreUpdate{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #323232;
    margin-top: 10px;
  }
  .tituloNombreCard{
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #323232;
    margin-top: 10px;
  }
.tituloRazon{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #323232;
    margin-top: 10px;
    opacity: 0.6;
}
.tituloEnter{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #323232;
    margin-top: 10px;
}
.calendarContainer{
    padding:20px;
    max-height: 500px;
    overflow: scroll;
}
.calendarContainerName{
    margin:10px
}
    
  .botonEliminar{
    background-color: #d31313 !important;
    padding: 15px 15px;
    margin-top: 20px;
    margin-right: 50px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgba(211, 19, 19, 0.4);
    font-size: 16px;
    color: #ffffff !important;
  }

.selectedTab{
  background-color: #093C5D !important;
  color:white !important;
  opacity:1 !important
}

.selectedTabEvent{
  background-color: #093C5D !important;
  color:white !important;
  opacity:1 !important;
  cursor: default !important;
}
 
.unselectedTab{
  background-color: #022338 !important;
  color:white !important;
  opacity:1 !important

}

.columnaTres{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom:24px ;
}

.contenedorFechas{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
  border: solid 1px #c3c3c3; 
  padding-left: 18px;
  padding-right: 12px;
  border-radius: 4px;
}

.textoFechas{
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #222;
  opacity: 0.6;
}

.calendario{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.buttonCancelCalendar{
  width: 130px;
  height: 40px;
  border: 1px solid #C7CCDC;
  border-radius: 8px !important;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  margin-bottom: 10px;
}

.buttonCancelCalendarText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px !important;
  color: #093C5D;
}

.conteinerSave{
  margin-left: 24px;
}

.buttonSaveCalendar2{
  width: 130px;
  height: 40px;
  background: #FABB00;
  border-radius: 8px !important; 
  background-image: url('/src/assets/svg/Save.svg');
  background-repeat: no-repeat;
  background-position: 20px;
  cursor: pointer;
  border-color:transparent
}

.buttonSaveCalendarText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #093C5D;
  margin-left: 10px;
  border-radius: 8px !important;
}

.contenedorColaborator {
  box-sizing: border-box;
  height: auto;
  padding-left: 10%;
  padding-right: 10%;
  /*width: 583px;*/
  margin-bottom: 20px;
}

.contenedorSelecioneColaborador
{
  text-align: center;
  margin: auto;
  display: flex;
}

.divtotalesUniverso{
  background-color: #093c5d;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  color: white;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.textoDivTotales {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin-right: 10px;
  color: white;
  font-weight: 400, "Regular";
  margin: auto;
}

.contenedortotales
{
  text-align: center;
  margin: auto;
  display: flex;
}

.divtotalesyellowl {
  background-color: #fabb00;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  border-radius: 0px 8px 8px 0px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 10%;
  cursor: pointer;
}

.contenedorTotalesText{
  width: 100%;
  
}

.divtotalesSelecionados {
  background-color: #093c5d;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  color: white;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 100%;
  cursor: pointer;
}

.textoDivTotalesSelecionados {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin-right: 10px;
  color: white;
  font-weight: 400, "Regular";
  margin: auto;
}

.DivSeleccionadosUniverso
{
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 50%;
  margin-top: 45px;
  padding: 10px;
  z-index: 2;
  top: 24%;
  left: 165px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fieldsetUpdateFiltro {
  border-radius: 4px;
  height: 18px;
}

.checkboxitemAvailableCollaborators {
  display: inline-block;
  position: relative;
  text-align: left;
}

.inputUpdate {
  width: 85%;
  padding-top: 4px;
  font-family: "Roboto", sans-serif !important;
  font-size: 13px;
  border: none;
  height: 20px;
}

.contenedorListaAvailableCollaborators {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;

  
}

.listaNombresAvailableCollaborators {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;
}


.checkboxitemAvailableCollaborators {
  display: inline-block;
  position: relative;
  text-align: left;
}

.checkboxitemAvailableCollaborators input[type="checkbox"]:checked + label:after {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.checkboxitemAvailableCollaborators label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border: 1px solid #c7ccdc;
  background-color: transparent;
}

.checkboxitemAvailableCollaborators input[type="checkbox"] {
  display: none;
}

.botonAgregar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  font-size: 14px;
  width: 129px;
  height: 40px;
  border-radius: 8px !important;
  margin-left: 24px;
  border: none;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;
}

.pc_iconoAgregarDos{
  background-image: url('/src/assets/svg/icono_agregar_dos.svg');
  height: 13.33px;
  width: 13.33px;
  margin-right: 6.83px;
}

.textoAgregar {
  margin-left: 12px;
  font-family: "Roboto", sans-serif;
  color: #093c5d;
  font-size: 16px;
  font-weight: 500;
}

.DivSeleccionados {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 40%;
  margin-top: 45px;
  padding: 10px;
  z-index: 1;
  top: 24%;
  left: 165px;
}

.DivSeleccionadosCollaborators{
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  width: 40%;
  margin-top: 45px;
  padding: 10px;
  z-index: 1;
  bottom: 20%;
  left: 165px;
}

.checkboxitemSelecionados {
  display: inline-block;
  position: relative;
  text-align: left;


}

.contenedorLista {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;
}

.listaNombres {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;

  
}

.checkboxitemSelecionados {
  display: inline-block;
  position: relative;
  text-align: left;


}

.EliminarImg {
  margin-right: 5px;
}

.divtotalesyellow {
  background-color: #fabb00;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  border-radius: 8px 0px 0px 8px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  border: none;
  width: 10%;
  cursor: pointer;
}

.botonEliminarList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #fbe7e7;
  color: #093c5d;
  font-weight: 500;
  background-color: #fbe7e7;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  cursor: pointer;
}