.c_contenedorPrincipal{
    width: 100%;
    height: auto;
    min-height: 450px !important;
    background-color:white;
    border-radius: 12px;
    margin-top: 23px;
    padding-bottom: 20px;
}
.cardsCampaign{
    margin-top:  30px;
    margin-left: 20px;    
}
.screenBtns{
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;    
}
.findCampaign{
    margin-left: 20px;
    margin-top:  20px;
}
.filtersCampaign{
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    margin-top:  20px;
}
.msgCarga{
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}

.containerBtns{
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
}

.c_contenedorTitulo{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.c_circuloAmarillo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: #fabb00;
    border-radius: 50%;
    cursor: pointer;
}

.c_contenedorTitulosEstado{
    display: flex;    
    margin-left: 8px;
    gap: 15px;
}

.c_contenedorIconoSeguimiento{
    display: flex;
    align-items: center;
    margin-left: 16.5px;
    margin-top: 13px
}

.c_circuloBlanco{
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
}

.c_lineaSeguimiento{
    height: 1px;
    background-color: #c7ccdc;
    width: 60px;
}

.c_circuloGris{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: #c7ccdc;
    border-radius: 50%;
    cursor: pointer;
}

.c_tituloProceso{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 15.23px;
    letter-spacing: 0.36px;
    margin-right: 25.50px;
    cursor: pointer;
}

.c_tituloProcesoSeleccionado{
    font-weight: 500;
}

.contenedorLoadingPrincipal{
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.loadingPrincipal{
    background-image: url('../../../assets/loading.gif');
    width: 300px;
    height: 300px;
    background-size: cover;
  }

.contenedorMensajeCarga{
    width: 96%;
    height: auto;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mensajeCarga{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    letter-spacing: 0.26px;
    color: #303030;
}

.buttonSaveRange{
    width: 136px;
    height: 40.96px;
    background: #FABB00;
    border-radius: 8px !important; 
    background-image: url('/src/assets/svg/Filtrar.svg') !important;    
    background-repeat: no-repeat;
    background-position: 20px;
    cursor: pointer;
    border-color:transparent
  }
  

.buttonRangeText{
    width: 136px;
    height: 40.96px;
    background: #FABB00;
    border-radius: 8px !important;
    margin-left: 10px;
  }