:root {
  --borderPillsLeft: 18px;
  --borderPillsRight: 18px;
  --background: #f5f6f9;
}

.validate__border {
  padding: 20px 20px 20px 20px;
  border-radius: 12px;
  border: solid 1px #11ca73;
  width: 90vh;
  max-width: 90vh;
}

.novalidate__border {
  padding: 20px 20px 20px 20px;
  border-radius: 12px;
  border: solid 1px #11ca73;
  width: 91vh;
  max-width: 90vh;
  margin-top: 15px;
  min-width: 70vh;
}

.novalidate__border2 {
  padding: 20px 20px 20px 20px;
  border-radius: 12px;
  border: solid 1px #11ca73;
  width: 80vh;
  max-width: 20px;
  margin-top: 15px;
  min-width: 70vh;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #093c5d !important;
}

.MuiInputLabel-outlined {
  color: #093c5d !important;
}

.Fecha-de-nacimiento {
  margin: 12px 31px 13px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
}

.Return {
  margin: 50px 0 3px 18px;
  opacity: 0.8;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
  cursor: pointer;
  text-align: center;
}

.MuiTabs-root.collaborator {
  background: var(--background) !important;
  border-top-left-radius: var(--borderPillsLeft) !important;
  border-top-right-radius: var(--borderPillsRight) !important;
}

.Tab__SelectedFirst {
  border-top-left-radius: 18px !important;
}

.Tab__Selectedlast {
  border-top-right-radius: 18px !important;
}

.Tab__NoSelectedFirst {
  border-top-left-radius: 18px !important;
  background: #e5e9eb !important;
}

.Tab__NoSelectedLast {
  border-top-right-radius: 18px !important;
  background: #e5e9eb !important;
}

.Tab__Selected {
  font-weight: bold !important;
  color: #fff !important;
  background-color: #022338 !important;
  width: 20% !important;
}

.Tab__NoSelected {
  font-weight: bold !important;
  color: #093c5d !important;
  background-color: #e5e9eb !important;
  width: 20% !important;
}

.Tab__background {
  background-color: #023832 !important;
  border-bottom: 5px solid #11ca73 !important;
}

.filledAll .PrivateTabIndicator-colorSecondary-12,
.filledAll .PrivateTabIndicator-root-471,
.filledAll .PrivateTabIndicator-colorSecondary-473,
.filledAll .MuiTabs-indicator {
  background-color: #11ca73 !important;
}

.PrivateTabIndicator-colorSecondary-12,
.PrivateTabIndicator-root-471,
.PrivateTabIndicator-colorSecondary-473,
.MuiTabs-indicator {
  background-color: gray !important;
  height: 5px !important;
  /*width: 20% !important;*/
}

.Tab__Selected .MuiTab-wrapper,
.Tab__NoSelected .MuiTab-wrapper {
  /* flex-direction: row-reverse !important; */
  align-items: center;
  margin-right: 10px !important;
  cursor: pointer;
}

.filledAll .MuiSvgIcon-root {
  margin-left: 20px !important;
  color: #11ca73 !important;
}

.MuiTab-labelIcon {
  min-height: 0px !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 5px;
  display: inline-block;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
}

.custom-file-upload span {
  background-color: red;
  position: absolute;
  margin-top: -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.custom-file-upload-text {
  font-size: 10px;
  text-align: right;
}

.custom-file-upload-text label {
  font-size: 15px;
}

.collaborator__candidate__container {
  background-color: white;
}

.Expediente {
  border-right: 1px solid #c7ccdc;
  height: 100%;
}

.Expediente > * {
  padding-right: 5px;
}

.file__tags {
  position: relative;
}

.custom-file-upload-expediente {
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.text-cancel-file-expediente {
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: rgb(243, 96, 96);
  width: 15px;
  height: 15px;
  margin-top: -35px;
  border-radius: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.labels__files > * {
  display: block !important;
}

.labels__files label {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
}

.labels__files a {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2186c6;
}

.labels__files span {
  font-size: 12px !important;
  color: #222222;
}

.border__green {
  border: 2px solid #11ca73 !important;
}

.icons__container > * {
  display: flex !important;
}

.Header__Candidate {
  background-color: #022338 !important;
  width: 100% !important;
  border-radius: 20px;
}

.Header__Candidate img {
  position: absolute;
  margin: 15px;
  margin-left: 50px;
}

.Header__Candidate h1 {
  color: white !important;
}

.AGREE_INFORMATION {
  padding-top: 0px !important;
}

.AGREE_INFORMATION h2 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #093c5d;
}

.AGREE_INFORMATION button {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: left !important;
}

.AGREE_INFORMATION button.buttonSave {
  background-color: #11ca73 !important;
}

.AGREE_INFORMATION button.buttonSave span {
  color: white !important;
}

.AGREE_INFORMATION button > span > span {
  width: 100%;
  text-align: left !important;
}

.buttonSaveCompletes {
  background-color: #11ca73 !important;
  color: white !important;
  border-radius: 8px !important;
}

.c-red {
  color: red !important;
}
.button-arrow-tabs{
color: #093C5D !important;
font-weight: bold !important;
border-radius: 8px !important;
}


/* #benefitiary {
  padding-top: 17.5px !important;
} */

.view-document {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #2186C6;
}
.cursor{
  cursor: pointer;
}
.image-file{
  cursor: pointer;
  
}
.span-file{
  display: block;
  font-size: 12px;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-child {
  /* flex: 2; */
  flex: 9999;
}  
.flex-child-2 {
  /* flex: 1;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important; */
  flex: 1 1;
  display: flex !important;
  /* justify-content: flex-end !important; */
  align-items: center !important;
  margin-right: 7px;
}
.display-flex-important{
  display: flex !important;
}
.aviso-retencion{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lbViendo{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #093c5d;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: -20px;
}

.btn-reject-information{
  box-sizing: border-box !important;
  /* position: absolute !important; */
  width: 100% !important;
  height: 37px !important;
  left: 714px !important;
  top: 586px !important;
  border: 1px solid #C7CCDC !important;
  border-radius: 8.5px !important;
  text-align: left !important;
  padding-left: 30px !important;
  cursor: pointer !important;
}

.btn-accept-information{
  box-sizing: border-box !important;
  /* position: absolute !important; */
  width: 100% !important;
  height: 37px !important;
  left: 714px !important;
  top: 586px !important;
  border: 1px solid #C7CCDC !important;
  border-radius: 8.5px !important;
  text-align: left !important;
  padding-left: 30px !important;
  cursor: pointer !important;
  background-color:#11CA73 !important;
  color: white !important;
}
.text-accept-information{
  color: #093C5D;
}

.lbViendo{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #093c5d;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: -20px;
}

.dialogContainer {
  padding:15px;
}

.containerTitle{
  display: flex;
}

.centerContainer{
  display: flex;
  align-content: center;
  justify-content: center !important;
  text-align: center;
}

.contenedorCerrarModal{
  width: 5%;
  display: flex;
  margin-top: 0px;
  align-items: center;
}

.iconoCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
  width: 13.18px;
  height: 13.18px;
  cursor: pointer;
  margin-bottom: auto;
  margin-left: auto;
}

.iconoCerrarModal:hover{
  filter: opacity(0.4) drop-shadow(0 0 0 red); 
}

.dialogFields {
  margin-bottom:24px !important;
}

.dialogFieldsSDI {
  margin-bottom:10px !important;
}

.buttonCancelCalendar{
  width: 129px;
  height: 40.96px;
  border: 1px solid #C7CCDC;
  border-radius: 8px !important;
  cursor: pointer;
}

.buttonCancelCalendarText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px !important;
  color: #093C5D;
}

.conteinerSave{
  margin-left: 24px;
}

.buttonSaveCalendarText{
  width: 136px;
  height: 40.96px;
  background: #FABB00;
  border-radius: 8px !important;
  margin-left: 10px;
}

.buttonSaveCalendar{
  width: 136px;
  height: 40.96px;
  background: #FABB00;
  border-radius: 8px !important; 
  background-image: url('/src/assets/svg/SaveCalendar.svg');
  background-repeat: no-repeat;
  background-position: 20px;
  cursor: pointer;
  border-color:transparent
}

.buttonSaveCalendar2{
  width: 136px;
  height: 40.96px;
  background: #FABB00;
  border-radius: 8px !important; 
  background-image: url('/src/assets/svg/Save.svg');
  background-repeat: no-repeat;
  background-position: 20px;
  cursor: pointer;
  border-color:transparent
}

.buttonSaveCalendarText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px !important;
  color: #093C5D;
}

.dialogFieldsErrorAlert{
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 0px;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.dateEditSalaryContainer{
  display : flex;
  margin-bottom:24px;
  align-items: center;
}

.dateEditSalaryText{
  margin-left: 10px;
  margin-right: auto;
}

.inputConfirm{
  width: 187.52px;
  height: 32.09px;
  border: 0.8px solid;
  margin-top: 6.41px;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  color: #222222;
  opacity: 0.8;
  margin-left: auto;
  margin-right: auto;
}

.em_mensajePrtincipal{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #222222;
  opacity: 0.8;
  text-align: center;
  line-height: 20px;
}

.buttonSaveCalendarInactive{
  background: #FABB00;
  border-radius: 8px !important; 
  cursor: no-drop;
  border-color:transparent;
  opacity: 0.5;
  width: 136px;
  height: 40.96px;
}