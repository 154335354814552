@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.button {
  height: 36px;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  background-color: #FABB00;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0px;
  border: none;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.button.disabled {
  opacity: 25%;
}

.text {
  font-family: Roboto, sans-serif;
  color: #093C5D;
  font-size: 14px;
}

.img {
  width: 16px;
  height: 16px;
}