@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.contenedorInformacionBancaria{
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 31px;
}

.contenedorInput{
  width: 100%;
}

.contenedorError{
  width: 100%;
  text-align: center;
  height: 23px;
}

.contenedorError > span{
  font-family: 'Roboto', sans-serif;
  color: #E0544B;
  font-size: 11px;
}

.contenedorBotones{
  width: 420px;
  margin-top: 317px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.iconoCrearEmpresaModal{
  background-image: url('/src/assets/svg/icono_crear_empresa_modal.svg');
  width: 14.67px;
  height: 13.33px;
  cursor: pointer;
  margin-right: 7px;
}

.botonCancelarModal{
  height: 40px !important; 
  cursor: pointer !important;
  width: 110px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  background-color: #ffffff;
  border: 0.8px solid #C7CCDC;
  font-family: 'Roboto';
  line-height: 16px;
  color: #093C5D;
}

.botonCrearEmpresaModal{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important; 
  cursor: pointer !important;
  width: 110px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  background-color: #FABB00;
  border: none;
  font-family: 'Roboto', sans-serif;
  line-height: 16px;
  color: #093C5D;
  margin-left: 16px;

}

.botonCrearEmpresaModal:active{
  background-color: #fddb73;
}