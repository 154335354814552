


.columnaGeneralPuesto{
    width: 50%;
    height: 50%;
  }
  
  .titulos{
    font-weight: bold;
    font-size: 16px;
    margin-left: 5%;
  }
  
  .filaInput{
    display: flex;
    justify-content: flex-start;
  }
  
  .espacioTitulos{
    width: 100px;
    height: 20px;
    margin: 0 0 0 150px;
    opacity: 0.8;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.40px;
    color: #303030;
  }
  
  .espacioTitulosTextarea{
    margin-top: 10px;
  }
  
  .espacioInputs{
    width: 300px;
    height: 20px;
    margin: 0 0 0 180px;
    padding: 8px 126px 9px 11px;
    border-radius: 10px;
  }
  
  .contenedorizquierdo{
    display: flex;
    justify-content: Right;
    align-items: Right;
  }
  
  .inputSelect{
    width: 100%;
    height: 40px;
    background-color: white !important;
  }
  .inputSelect:focus{
    background-color: rgba(0, 0, 0, 0) !important;
    color: yellow!important;
    height: 40px !important;
  }
  
  .optionSelect{
    height: 40px !important;
    background-color: white !important;
  
  }
  
  .optionSelect:focus{
    background-color: none !important;
    height: 40px !important;
    color: yellow!important;
  }
  
  .tituloDescripcion{
    font-size: 13px;
  }
  
  .espacioTextArea{
    width: 100%;
    font-size: 100px !important;
    color: red !important;
  }
  
  .espacioTextArea::placeholder{
    font-size: 100px !important;
    color: red !important;
  }
  
  .busquedaColaborador{
    margin-left: 10%;
    margin-top: 10px;
  }
  
  .botonAgregarPuesto{
    background-color: #fabb00;
  }
  
  .contenedorUsuarios{
    height: 300px;
    overflow-y: scroll;
  }
  
  .contenedorUsuariosPuesto{
    height: 100%;
  }
  
  .contenedorUsuariosPuestoInfo{
    height: 100%;
    margin-left: 25px;
  }
  .contenedorListaUsuariosPuesto{
    border-radius: 4px;
    border-radius: 4px;
    height: 50px;
    margin-top: 2px;
    width: 100%;
    list-style: none;
  }
  .contenedorLista{
    border: solid 1px #c7ccdc;
    border-radius: 4px;
    border-radius: 4px;
    height: 50px;
    margin-top: 2px;
    width: 95%;
  }
  .textoListaUsuario{
    color: #093c5d;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  
  .correoListaUsuario{
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222;
    opacity: 0.6;
  }
  
  .bottonAgregarUsuario{
    background-color: #fabb00;
    border-radius: 12px;
    margin: 5px 10px 4px 66px;
    padding: 4px 6px 4px 13px;
    box-shadow: 0 2px 4px 0 rgba(250, 187, 0, 0.4);
    border: none;
    
  }
  
  .textoBotonAgregaUsuario{
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #093c5d;
  }
  .ulLista{
    margin: 0px;
      padding: 0px;
    margin-left: 5%;
  }
  
  .alineacionPuestoUsuario{
    display: flex;
    justify-content:space-between;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  
  .iconoEliminaUsuario{
    display: flex;
    justify-content:flex-end;
  }
  
  
  .contenedorListaUsuarioBoton{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ContenedorBotonCrearOrganigrama{
    position: absolute;
    z-index: 1;
    top: 40px;
  }

