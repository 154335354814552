#WelcomeUser{
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 20px 20px 0;
}

#NoticesSpan {
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
  
#NoticeSpan {
    color: #2186c6;
}
  
#ReadMore {
    color: #fabb00;
}

#allNotices{
    margin: 30px;
    margin-bottom: 5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.MuiTypography-colorTextSecondary{
    color:white !important;
}

.ImageNotice{
    border-radius: 8px;
}

.barGraphLabels{
    width:100%;
    height: 15px;
    clear: both;
    margin-bottom: 5px;
}

.barGraphMonthLegend{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.444444px;
    color: #303030;
    float:left;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.barGraphTotal{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.444444px;
    float:right;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.barGraphBlue-1{
    color: #176698;
    font-weight: 700;
}

.barGraphMonth{
    width: 100%;
    margin-bottom: 10px;
}

.barGraphhLineBlue-1{
    background-color: #176698;
}

.barGraphBlue-2{
    color: #2186C6;
    font-weight: 700;
}

.barGraphhLineBlue-2{
    background-color: #2186C6;
}

.barGraphBlue-3{
    color: #2698E0;
    font-weight: 700;
}

.barGraphhLineBlue-3{
    background-color: #2698E0;
}

.barGraphBlue-4{
    color: #2FAEFF;
    font-weight: 700;
}

.barGraphhLineBlue-4{
    background-color: #2FAEFF;
}

.barGraphBlue-5{
    color: #4FBBFF;
    font-weight: 700;
}

.barGraphhLineBlue-5{
    background-color: #4FBBFF;
}

.barGraphLine{
    height: 10px;
    width: 100%;
    background: #EBDEDE;
    border-radius: 38.0089px;
    position: relative;
    z-index: 0;
}

.barGraphhLineColor{
    height: 10px;
    border-radius: 38.0089px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

#WatchNotice{
    height: 16px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fabb00;
}

.NoticeList{
    overflow-x: auto;
    width: auto;
    display: flex;
    margin: 20px;
}

.CardNotice{
    display: inline-block;
}