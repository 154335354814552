


.columnaGeneralPuesto{
  width: 100%;
  height: 100%;
}

.titulos{
  font-weight: bold;
  font-size: 16px;
  margin-left: 0!Important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.filaInput{
  display: flex;
  justify-content: flex-start;
}

.espacioTitulosEdit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px!Important;
  height: 40px;
  font-size: 14px;
  width: 100%;
}

.espacioTitulosTextarea{
  margin-top: 10px;
  font-weight: bold;
}

.espacioInputs{
  margin-top: 10px;
}

.contenedorizquierdo{
  margin-left: 10%;
}

.inputSelect{
  width: 100%;
  height: 40px;
  background-color: white !important;
}
.inputSelect:focus{
  background-color: rgba(0, 0, 0, 0) !important;
  color: yellow!important;
  height: 40px !important;
}

.optionSelect{
  height: 40px !important;
  background-color: white !important;

}

.optionSelect:focus{
  background-color: none !important;
  height: 40px !important;
  color: yellow!important;
}

.tituloDescripcion{
  font-size: 13px;
}

.espacioTextArea{
  width: 100%;
  font-size: 100px !important;
  color: red !important;
}

.espacioTextArea::placeholder{
  font-size: 100px !important;
  color: red !important;
}

.busquedaColaborador{
  margin-left: 10%;
  margin-top: 10px;
}

.botonAgregarPuesto{
  background-color: #fabb00;
}

.contenedorUsuarios{
  height: 270px!Important;
  overflow-y: scroll;
}

.contenedorUsuariosPuesto{
  height: 100%;
}

.contenedorUsuariosPuestoInfo{
  height: 100%;
  margin-left: 25px;
}
.contenedorListaUsuariosPuesto{
  border-radius: 4px;
  border-radius: 4px;
  height: 50px;
  margin-top: 2px;
  width: 100%;
  list-style: none;
}
.contenedorLista{
  border-radius: 4px;
  border-radius: 4px;
  height: 50px;
  margin-top: 2px;
  width: 99%!Important;
  border: solid 1px #c7ccdc!Important;
  background: none!Important;

}
.textoListaUsuario{
  color: #093c5d;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.correoListaUsuario{
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
  opacity: 0.6;
}

.bottonAgregarUsuario{
  background-color: #fabb00;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(250, 187, 0, 0.4);
  border: none;
  cursor: pointer
}

.textoBotonAgregaUsuario{
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #093c5d;
  cursor: pointer;
}
.ulLista{
  margin: 0px;
	padding: 0px;
  margin-left: 0%!Important;
}

.alineacionPuestoUsuario{
  display: flex;
  justify-content:space-between;
  align-items: center;
  text-align: center;
  height: 100%;
}

.iconoEliminaUsuario{
  display: flex;
  justify-content:flex-end;
}

.iconoEliminaUsuarioIMG{
  cursor: pointer;
}

.iconoEliminaUsuario >  button{
  border: none;
  background: none;
}

.contenedorListaUsuarioBoton{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContenedorBotonCrearOrganigrama{
  position: absolute;
  z-index: 1;
  top: 40px;
}


.contenedorTituloModale
{
  width: auto!important;
  position: initial;
}

.ColumnaEditarPuesto
{
  float: left;
  padding: 10px;
}

.dialogoContento 
{
  max-width: 100%!Important;
}

.fieldsetUpdateFiltro {
  border-radius: 4px;
  height: 18px;
}

.checkboxitemColaboradores {
  display: inline-block;
  position: relative;
  text-align: left;
}

.inputUpdateColaboradores {
  width: 85%;
  padding-top: 4px;
  font-family: "Roboto", sans-serif !important;
  font-size: 13px;
  border: none;
  height: 20px;
}

.contenedorListaColaboradores {
  margin: 0;
  padding: 0;
  margin-left: 17px;
  text-align: left;
}

.listaNombresColaboradores {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 5px;
  marker-start: none;
  list-style: none;
}

.checkboxitemColaboradores {
  display: inline-block;
  position: relative;
  text-align: left;
}

.pc_iconoAgregarDosColaborador{
  background-image: url('/src/assets/svg/icono_agregar_dos.svg');
  height: 13.33px;
  width: 13.33px;
  margin-right: 6.83px;
}

.botonAgregarColaborador {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fabb00;
  border-radius: 8px !important;
  /* background-image: url('/src/assets/svg/SaveCalendar.svg'); */
  font-size: 14px;
  width: 129px;
  height: 40px;
  border-radius: 8px !important;
  margin-left: 24px;
  border: none;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;
}

.textoAgregarColaborador {
  margin-left: 12px;
  font-family: "Roboto", sans-serif;
  color: #093c5d;
  font-size: 16px;
  font-weight: bold;
}

.contenedorPrincipalIncidencia {
  box-sizing: border-box;
  height: auto;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 27px;
  width: 640px;
}

.contenedorColaboradoresDisponibles{
  width: 63%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.DivSeleccionadosUniverso
{
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  width: 60%;
  padding: 10px;
  z-index: 2;
  top: 80%;
  left: 18%;
}

.pc_inputBuscador{
  display: flex;
  align-items: center;
  width: 420px;
  min-width: 420px;
  box-sizing: border-box;
  border-radius: 8px;
  height: 47px;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #303030;
}

.botonGuardarPuesto {
  background-color: #fabb00;
  padding: 15px 15px;
  margin-top: 20px;
  margin-right: 50px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px;
  color: rgb(9, 60, 93);
}