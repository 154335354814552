.containerTableDynamic{
    margin-top:  20px;
    margin-left: 20px;
    width: 100%;
    height: 650px;
    overflow-y: scroll; 
    overflow-x: scroll; 
}

.containerSquareDynamic{
    overflow-y: scroll;  
    overflow-x: scroll; 
    width: 100%;    
}

.squareReport {
    border: 1px solid ;
    border-collapse: collapse;
    width: 100%;    
}

.squareReportCell {
    border:#CACACA 1px solid;
  }