@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.contenedorTitulo{
    /*width: 520px;*/
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: #093C5D;
    display: flex;
    justify-content: center;
}

.tituloCrearEmpresa{
    width: 80%;
    margin-top: 20px;
    margin-left: 38px;
}

.contenedorCerrarModal{
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedorTabs{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.contenedorBotones{
    margin-top: 30px;
    display: flex;
    margin-bottom: 26px;
    padding-bottom: 26PX;
    width: 854px;
    display: flex;
    justify-content: center;
}

.botonCancelar{
    width: 110px;
    height: 34px;
    background-color: #ffffff;
    border: 0.8px solid #E1E1E1;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #093C5D;
    cursor: pointer;
    margin-right: 16px;
}

.divscrollcon{
    overflow-y: scroll;
}

.divscrollcon::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #d6d6d6;
    width: 10px !important;
  }