.boxTable {
  width: 97%;
  height: auto;
}

.boxTitle {
  width: 100%;
  height: 32px;
  background-color: #e9ebf1;
  display: flex;
  align-items: center;
}

.boxOutsoursers {
  border: solid 1px #eaeaea;
  border-radius: 0 0 8px 8px;
  width: 100%0;
}

.tituloUno{
  width: 50%;
}

.boxDataSourcers {
  width: 100%;
  height: 49px;
  display: flex;
  border-bottom: 1px solid #ccc;
}
.boxLogoName {
  width: 50%;
  display: flex;
  align-items: center;
}

.boxRazonSocial {
  display: flex;
  align-items: center;
  width: 50%;
}

.boxTooltip {
  width: 100px;
}
