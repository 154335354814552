input.collaboratorTab__searcher::-webkit-input-placeholder {
    /* color: #c7ccdc; */
    color: #838383;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
}

input.collaboratorTab__searcher::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #838383;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
}

input.collaboratorTab__searcher::-moz-placeholder {
    /* Firefox 19+ */
    color: #838383;
    font-style: italic;
    font-weight: bold;
}

input.collaboratorTab__searcher:-ms-input-placeholder {
    /* IE 10+ */
    color: #838383;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
}

input.collaboratorTab__searcher:-moz-placeholder {
    /* Firefox 18- */
    color: #838383;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
}

.buttonContent {
    border: none;
    background-color: #fabb00;
    color: #093c5d;
    padding: 0;
    border-radius: 8px !important;
    font-weight: bold;
    margin-right: 8px;
    position: relative;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.3s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.buttonContent:hover {
    background: #fddb73 ;
}

.btnY {
    background-color: #fabb00;
    margin: 0;
    border-radius: 8px !important;
    font-weight: bold;
    transition-duration: 0.4s;
    /* z-index: 999; */
}

.btnY:hover {
    background: #fddb73;
}

/* .buttonContent:active {
    background-color: #c0c0c0;
    background-size: 100%;
    transition: background 0s;
} */

.btnY:active {
    background-color: #fddb73;
    background-size: 100%;
    transition: background 0s;
}