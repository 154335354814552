
.divDisabled{
    display: flex;
    background-color: rgb(221,227,233);
    height: 38px;
}

.divTextDisabled{
    width: 60%;
    text-align: center;
    line-height: 38px;
    background-image: url('/src/assets/svg/icono-locke.svg');
    background-repeat: no-repeat;
    background-position: 25% center;
  }

.textDisabled{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.666667px;
    color: #323232;
}

.divTextContact{
    width: 40%;
    text-align: center;
    line-height: 38px;
}


.textContact{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.666667px;
    color: #093C5D;
  }