.pc_contenedorPrincipal{
  background-color: #fff;
  height: 100%;
  width: 100%;
  min-height: 10vh;
  min-width: 50vh;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.pc_contenedorPrincipalSmall{
  background-color: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}


.tituloFacturacionPagos{
  margin-top: 0px;
}

.contenedorLoading{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading{
  background-image: url('../assets/loading.gif');
  width: 300px;
  height: 300px;
  background-size: cover;
}

.loadingSmall{
  background-image: url('../assets/loading.gif');
  width: 100px;
  height: 100px;
  background-size: cover;
}