.flexModalPerceptions{
  display: flex;
  justify-content: space-between; 
  align-items: center;
}
.mb24ModalPerceptions{
  margin-bottom: 24px;
}

.mb26ModalPerceptions{
  margin-bottom: 26px;
}

.ml32ModalPerceptions{
  margin-left: 32px;
}
.mr32ModalPerceptions{
  margin-right: 32px;
}


.mr10ModalPerceptions{
  margin-right: 10px;
}
.contenedorModalPerceptions{
  display: flex;
  justify-content: center;
  width: 500px;
  box-sizing: border-box;
}
.contenedorModalPerceptions2{
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.tituloModalPerceptions{
  font-size: 24px;
  color: #093c5d;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.inputModalPerceptions{
  border-radius: 8px;
  width: 210px;
  height: 32px;
  border: solid 1px #c7ccdc;
  padding-left: 11px;
  font-family: 'Roboto', sans-serif;
  opacity: 0.8;
  font-size: 13px;
  color: #303030;
}

.selectModalPerceptions{
  width: 210px;
  height: 32px;
  padding-left: 11px;
  font-family: 'Roboto', sans-serif;
  opacity: 0.8;
  font-size: 13px;
  color: #303030;
}

.textoModal{
  font-family: 'Roboto', sans-serif; 
  font-size: 13px;
  color: #303030;
}


.contenedorError{
  width: 500px;
  height: 20px;
  text-align: right;
  margin-bottom: 10px;
}

.contenedorError > span{
  color: #E0544B;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.botonbotonCancelar{
  width: 129px;
  height: 42px;
  border-radius: 8px;
  border: 1px solid #C7CCDC;
  background-color: #ffffff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #093C5D;
  cursor: pointer;
}


.botonbotonGuardar{
  width: 129px;
  height: 42px;
  border-radius: 8px;
  border: none;
  background-color: #FABB00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  gap: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #093C5D;
  cursor: pointer;
}

.botonbotonGuarda2{
  width: 200px;
  height: 42px;
  border-radius: 8px;
  border: none;
  background-color: #FABB00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  gap: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #093C5D;
}