.pc_tituloContenedorPrincipal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    margin-left: 26px;
  }

  .p_contenedorTablaDispersion{
    margin-top: 26px;
    width: 33%;
  }

  .divGraphicConteiner{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .ivGraphic{
    height: 390px;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(9, 60, 93, 0.2);
    border-radius: 12px;
  }

  .alineacionTituloGraficas{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.171429px;
    color: #093C5D;
    text-align: center;
  }

  .textGraphic{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.444444px;
    color: #303030;
    mix-blend-mode: normal;
    opacity: 0.6;
  }

  .textNumberGraphicBlue{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.444444px;
    color: #2186C6;
    margin: 0px;
  }