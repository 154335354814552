.denyButton{
    color: #222 !important;
    border: 1px solid #eee !important;
    border-radius: 8px !important;
}

.confirmButton{
    background: #ea5455 !important;

}
