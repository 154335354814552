.dialogContainer {
    padding:15px;
}

.divRadio{
    width: 80%;
    margin: auto
}

.centerContainer{
    display: flex;
    align-content: center;
    justify-content: center !important;
}

.radioitem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
    display: flex;
    justify-content: left;
    margin-top: 15px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #303030;
}

.radioitem input[type="radio"] {
    display: none;
}

.radioitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    width: 15px;
    height: 15px;
    border-radius: 11px;
    border: 1px solid #c7ccdc;
    background-color: transparent;
}

.radioitem input[type="radio"]:checked + label:after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 3px;
    left: 9px;
    content: " ";
    display: block;
    background: #fabb00;
  }

  .titleNotificar{
    margin-left: 30px;
  }

  .checkboxitem {
    display: inline-block;
    position: relative;
    text-align: left;
    margin-left: 5px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #303030;
  }
  
  .checkboxitem input[type="checkbox"]:checked + label:after {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 3px;
    left: 3px;
    content: " ";
    display: block;
    background: #fabb00;
  }
  
  .checkboxitem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    width: 14px;
    height: 14px;
    border: 1px solid #c7ccdc;
    background-color: transparent;
  }
  
  .checkboxitem input[type="checkbox"] {
    display: none;
  }

  .contenedorBotones{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 31px;
  }

  .botonCancelar{
    background-color: #fff;
    border: 0.8px solid #C7CCDC;
    border-radius: 4px;
    width: 110px;
    height: 40px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #093C5D;
    cursor: pointer;
    margin-right: 16px;
  }

  .botonSiguiente{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 40px;
    background-color: #FABB00;
    border: none;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
    color: #093C5D;
  }