@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.cardCampaign{
    width: 310px;
    height: 200px;  
    background: #FFFFFF;
    border-radius: 15px !important;
    box-shadow: 0px 3.156334638595581px 12.625338554382324px 0px #093C5D33 !important;
}
.contentCampaign{
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}
.topCampaign{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; 
    width: 300px;
}
.selectCampaign{
    width: 100 px;
    margin-left: 10px;
}
.infoBono{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color:#838383;   
    margin-left: 14px; 
}
.infoBonoCenter{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color:#838383;   
    margin-left: 14px; 
}
.infoCampaignCenter{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color:#355F7A;   
    margin-left: 14px; 
}
.remandingDays {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FF3333;
}
.btnCampaign{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    width: 140px;
}
.switchCampaign{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.actCampaign{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.3611111044883728px;
    text-align: left;
    color: #303030 ;
}
.infoCard{
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    margin-top: 20px;
}
.titleCampaign{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.7014076709747314px;
    text-align: center;
    color: #303030;
}
.periodoCampaign{
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #838383;
}
.menCampaign{
    margin-left: 10px;
}
.crearCard{
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 20px;
}
.crearMensaje{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    color: #222222;
}
.definirCard{
    display: flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items: center;
    width: 280px;
    margin-top: 8px;
}
 .definirContain{  
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}
.obtevosTxt{
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color:#2186C6
}
.msjDefinir{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    color: #222222;
}
.msjEvaluar{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color:#222222;
}
.evaluarContain{
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center; 
}

.evaluarTxt{
    display: block;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    color: #2186C6;
}

.msjEvaluar{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color:    #222222;
    
}

.ev-line{
    border-left: 1px solid #093C5D;
    height:30px;    
}


.contenedorLoading{
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
 