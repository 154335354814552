.c_contenedorPrincipalReport{
  width: 100%;
  height: auto;
  min-height: 450px !important;
  background-color:white;
  border-radius: 12px;
  margin-top: 0px;
  padding-bottom: 20px;
}
.btnsInformes{
    margin-top: 20px;
    display:flex;
    flex-direction: row ;
    justify-content: space-between;
    width: 100%;
}
.btnsInformesFiltros{
    display:flex;
    flex-direction: row ;
}
.actionsReports{
  display:flex;
  flex-direction: row ;
  justify-content: space-between;  
  align-items: center;
  margin-right: 100px;
  gap:20px;
  width: 200px;
}
.r-line{
  border-left: 1px solid #CACACA;
  height:35px;    
 }
 .pointR{
  cursor: pointer;
 }
 .senR{
    margin-left:30px;
    cursor: pointer;
 }

.btnFiltrarReports{
        font-family: "Roboto", sans-serif !important;
        padding: 15px 15px;   
        border-color: #C7CCDC;
        border-width: 1px;
        border-style: solid;
        cursor: pointer;
        font-weight: 500;
        border-radius: 4px !important;
        height: 36px;
        width: 100px;
        font-size: 14px;
        color:#323232;    
      }

.reportBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        height: 100%;
      }

.c_contenedorPrincipalTableReport{
  padding: 20px;  
}

.cellSpanTitleReport{
  width: 150px;
}
.cellSpanTitleReportName{
  width: 280px;
}
.cellSpanTitleButonns
{
  width: 80px;
}
.cellSpanTitleReportDes{
  width: 550px;
}

.containerIcons{
  display: flex;  
  justify-content:space-around;
}

.deleteReport{
  cursor: pointer;
}
.editReport{
  cursor: pointer;
}
.downExcelReport{
  cursor: pointer;
}

.TitleReportSingle{    font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  padding-bottom: 0px;
  color: #093c5d;
}

.contenedorReportSingle{
  background-color: none;
  margin-top: 0px;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
  align-items: center;
  width: 95%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 40px;
  min-height: 300px;
}

