.contenedor{
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  padding-top: 30px;
}

.contenedorOpciones{
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.textoOpciones{
  color: #323232;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.select{
  width: 110px; 
  height: 35px;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 30px;
  padding: 2px 3px 2px 3px;
  cursor: pointer;
}


.iconoSubirArchivo{
  background-image: url('/src/assets/svg/icono_subir_archivo_empresa.svg');
  width: 11px;
  height: 14px;
  margin-right: 8px;
}

.boton{
  width: 150px;
  height: 32px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #093C5D;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 2px 3px 2px 3px;
}

.botonCambiar{
  background-color: #ffffff;
  border: 0.8px solid #C7CCDC;
}

.botonSeleccionar{
  background-color: #FABB00;
  border: none;
  margin-left: 30px;
  border-radius: 8px;
}

.tituloTabla{
  background-color: #E9EBF1;
  height: 32px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  width: auto;
  padding-left: 18px;
  padding-right: 20px;
}

.tabla{
  border-collapse: collapse;
}

.contenedorTabla{
  width: 96.5%;
  margin-left: 20px;
  height: auto;

  overflow: scroll;
	overflow-x: auto;

  white-space: nowrap;
}

.contenedorTabla::-webkit-scrollbar {
	-webkit-appearance: none;
	height: 14px;
}

.contenedorTabla::-webkit-scrollbar-thumb {
	background-color: #2186c6;
	border-radius: 8px;
	border: 3px solid #fff;
}

.contenedorTabla::-webkit-scrollbar-hover {
	background-color: rgba(9, 60, 93, 0.5);
}

.primeraColumna{
  padding-left: 30px;
}

.r_select{
  background-color: white;
  border: none;
  width: 200px;
  height: 32px;
  border-radius: 5px;
  color: #323232;
  font-size: 12px;
  padding-left: 5px;
  margin-left: 10px;
}

.r_titulo{
  color: #323232;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  width: auto;
}

.r_tituloFecha{
  width: auto;
  color: #323232;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-left: 10px;
}

.r_flex{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}