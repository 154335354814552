.MuiDialog-paperWidthSm{
   border-radius: 10px !important;
}
.containerMdalPrestacion
{
   display: flex; 
   flex-direction: column;
   justify-content:flex-start;
   align-items: center;
   align-content:flex-start;
   width: 480px !important;    ;
   height: auto;
}

.titlePrestacion{    
    font-family: "Roboto", sans-serif !important;    
    font-weight: 700;
    font-size: 26px;    
    color:#093C5D ;
}

.oneDivPrestacion{
  display: flex;
  flex-flow: row nowrap;  
  justify-content:flex-start ;
  justify-content: flex-start;  
  align-items: baseline ;   
  width: 100% !important;    
  margin-top:2px; 
  margin-bottom: 10px;   
}

.twoInputsPrestacion{
    display: flex;
    flex-direction: row;  
    justify-content: space-between;  
    align-items: center ;    
    padding: 0 !important;    
    width: 100% !important;      
    height: auto;
}

.twoInputsPrestacionAdmin{
  display: flex;
  flex-direction: row;  
  justify-content: space-between;  
  align-items: center ;    
  padding: 0 !important;    
  width: 100% !important;      
  height: auto;
  margin-top: -20px;
}
.twoBtnsPrestacion{
  display: flex;
  flex-direction: row;  
  justify-content: center;  
  align-items: center;     
  padding: 0 !important;   
  width: 100% !important; 
  height: auto;
}

.nombrePrestacion{
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}

.categoriaPrestacion{
  padding-left: 0 !important;   
  padding-top: 5px !important;          
} 

.empresaSede{
  padding-left: 0 !important;         
  padding-top: 5px !important;           
} 

.companyArea{
  padding-left: 0 !important;         
  padding-top: 5px !important;    
 }  

.containerUploadImgPrestacion{
  padding-left: 0 !important;         
  padding-top: 0 !important;    
}

.custom-file-upload-image-prestacion{
  display: flex;    
  justify-content:space-around;
  align-items: center;
  color: rgb(9, 60, 93);   
  font-size: 16px;    
  border:1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;  
  margin-top: 16px;  
  padding-top: 0 !important;     
  width: 225px;
  height:38px; 
}

.custom-file-upload-image-prestacion-del{
  display: flex;    
  justify-content:space-around;
  align-items: center;
  color: rgb(9, 60, 93);   
  font-size: 16px;    
  border:1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;  
  margin-top: 16px;  
  padding-top: 0 !important;     
  width: 225px;
  height:38px; 
}


.textLblimg{
  margin-left: -15px;
  font-family: "Roboto", sans-serif !important;    
  font-style: italic;    
}
.inputFilePrestacion{
  width: 200px;
}

.btnAdminColaborador{
        background-color: #9DB1BE;
        border: none;
        cursor: pointer;        
        height: 40px;
        width: 225px;
        border-radius: 8px !important;
        color: rgb(9, 60, 93);
        -webkit-border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        -ms-border-radius: 8px !important;
        -o-border-radius: 8px !important;
        font-family: "Roboto", sans-serif !important;    
        font-weight: 400;
        font-size: 13px;  
        font-style: italic;  
        font-weight: bold;
  }
   
   .btnContainerAdminColaborador{
    padding-left: 0 !important;        
    width: 190px;  
    height: 42px;
  }

   .evaluable  {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
   }

   .evaluableTxt{
    margin-left: -15px;
    font-family: "Roboto", sans-serif !important; 
    font-style: italic;     
    color:#093C5D ;
   }

   .switchPrestacionEv{
   margin-right: 5px;
   }

.restriccion{
  margin: 0 auto;  
}

.infobtnRestriccion{
  margin-top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btnRestriccion{
    background-color: #fabb00;
    padding: 15px 15px;   
    border: none;
    cursor: pointer;    
    border-radius: 8px !important;
    width: 150px;
    height: 21px;    
    color: rgb(9, 60, 93);
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
    font-family: "Roboto", sans-serif !important; 
    font-style: italic;     
    font-size: 12px;
    font-weight:400;

}
    
.containerRestriciones{
  display        : flex;
  flex-direction : row;
  justify-content:space-evenly;
  align-items    :center ;
  gap: 8px;
}

.selectRestriccion{  
  width:170px ;
}

.sueldoBase{
  width:250px ;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;
  gap: 3px;
}

.selectContrato{
width:250px ;
display: flex;
flex-direction: row;
justify-content:space-around;
align-items: center;
gap: 3px;
}

.MesesRestricccion{
  width:250px ;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  gap: 3px;
}

.mensajeInfo{
  width:250px ;
}

.txtAlertaRestriccion{
  color: rgb(9, 60, 93);
  font-family: "Roboto", sans-serif !important;  
  font-style: italic;
  font-size: 14px;
  font-weight:400; 
}

.txtConterPres{
  color: rgb(9, 60, 93);
  font-family: "Roboto", sans-serif !important;  
  font-style: italic;
  font-size: 14px;
  font-weight:400; 
}
.oneTxtPrestacion{
  display: flex;
  flex-flow: column nowrap;  
  justify-content:flex-start ;  
  align-items: baseline ;   
  width: 100% !important;    
  margin-bottom: 10px; 
}
.divCounterArea{
 width:50px;
 margin-top: -20px;
 padding-left: 405px;
}



.delREstriccion{
  cursor: pointer; 
  margin-left: 10px;
}

.containerButtons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;  
  margin-bottom: 10px;
}

.btnCancelPrestacion {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 42px;
  border-radius: 8px !important;
  border: solid 1px #c7ccdc;
  color: #093c5d;
  font-weight: 500;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  cursor: pointer;
}

.infobtnAddRestriccion{
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.txtBtnAdd{
  font-family: "Roboto", sans-serif;
  margin-left: 10px;
  color: #093c5d;
  font-weight: 700;
  font-size: 16px;  
}


.btnAddPrestacion {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 42px;
  border-radius: 8px !important;
  border: none;
  color: #093c5d;  
  background-color: #fabb00;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  cursor: pointer;
}  







