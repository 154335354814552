.contenedorReportIndividual{
    width: auto;
    height: auto;
    min-height: 450px !important;
    background-color:white;
    border-radius: 12px;
    margin-top: 23px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row ;
    justify-content: flex-start;
    align-items: flex-start;  
        
  }

.containerFilterInforme{
    width: 22%;
    display: flex;
    flex-direction: column ;
    justify-content: flex-start;
    align-items: flex-start;  
}

.buttonsReportSingle{
  padding: 20px;
  display: flex;
  flex-direction: row ;
  justify-content: flex-start;
  align-items: flex-start;  
  gap:20px;
}

.select{
  cursor:pointer;
}

.see{
  cursor:pointer;
}

.imgAplicar{
  margin-left: 20px;
}

.aplicar{
  cursor:pointer;
}

.containerFilter{
  display: flex;
  flex-direction: row ;
  justify-content:space-between;
  align-items: center;
  margin-right: 15px;
  gap:20px
}

.filter{
  cursor:pointer;
}

.save{
  cursor:pointer;
}

.separatorReportSingle{
  background-color : #E9EBF1;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row ;
  justify-content:space-between;
  align-items: center;
  
}
.filtersReport{
  width: 100%;
 
}

.selectoresReport{
  width: 100%;
  height: 500px; 
  overflow-y: scroll;  
}

.selectoresReport::-webkit-scrollbar {
  display: block;
  width: 8px;
}
.selectoresReport::-webkit-scrollbar-track {
  background: transparent;
}
  
.selectoresReport::-webkit-scrollbar-thumb {
  background-color: #D6D6D6;
  border: 5px solid #D6D6D6; 
}

.selectoresReport::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px; 
}

.selectoresReport::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 0px;
}

.inputInformes{
  height: 32.09px;
  border: 0.8px solid #c7ccdc;
  margin-top: 6.41px;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  color: #222222;
  opacity: 0.8;
  width: 100%;
}

.containerComponentReport{
  display: flex;
  flex-direction: column ;
  justify-content: flex-start;
  align-items: flex-start;  
  gap:5px;  
  margin: 15px;
}

.labelReport{
  width: 121px;
  height: 16px;
  top: 329px;
  left: 126px;
  opacity: 0.8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4375px;
  text-align: left;
  color: #323232;

}

.containerInputReport{
  width: 100%;
  display: flex;
  flex-direction: row ;
  justify-content: space-between;
  align-items: center;  
  gap:20px;
}




