
.contenedor{
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
}

.idss > CustomTab {
  margin-left: 34px;
}

.contenedorTitulo{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.61px;
  color: #323232;
  padding-top: 29px;
  padding-left: 22px;
}

.contenedorInput{
  border: solid 1px #c7ccdc;
  width: 454.5px;
  height: 47px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.input{
  width: 410px;
  border: none;
  box-sizing: border-box;
  padding-left: 15px;
  font-family: 'Roboto',sans-serif;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
}

.input:focus{
  border: none !important;
  outline: none;
}

.input::placeholder{
  font-family: 'Roboto',sans-serif;
  font-style: italic;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.6;
}

.iconoBuscar{
  background-image: url('/src/assets/svg/icono_buscar_dos.svg');
  width: 18.12px;
  height: 18.12px;
  margin-right: 14.44px;
}

.contenedorTabla{
  width: 97%;
  margin-left: 22px;
  margin-top: 35px;
}

.tabla{
  width: 100%;
  border-collapse: collapse;
}
.thead{
  background-color: #e9ebf1;
  height: 32px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030
  
}
.tituloTabla{
  width: auto
}

.primeraColumna{
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 32px;
}

.datoTabla{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  height: 40.81px;
  border-bottom: 1px solid #C7CCDC;
}

.registroAceptado{
  background-color: #daffe3;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  height: 40.81px;
}
.registroRechazado{
  background-color: #fdc1c1;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  height: 40.81px;
}

.registroBase{
  background-color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #303030;
  height: 40.81px;
}



.botonExterno{
  background-color: #fabb00;
  width: auto;
  height: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #093c5d;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  box-shadow: 0px 2px 8px rgba(250, 187, 0, 0.4);
  border-radius: 8px;
  cursor: pointer;
}

.botonExterno:active{
  background-color: #fddb73 !important;
}

.checkboxitem {
  display: inline-block;
  position: relative;
  text-align: left;
}

.checkboxitem input[type="checkbox"]:checked + label:after {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.checkboxitem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border: 1px solid #c7ccdc;
  background-color: transparent;
}

.checkboxitem input[type="checkbox"] {
  display: none;
}


.iconoDescargar{
  background-image: url('/src/assets/svg/icono_descargar_reporte.svg');
  width: 9.33px;
  height: 11.33px;
  margin-right: 10.83px;
}

.botonDescargar{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 8px 16.43px 9px 12.4px;
  cursor:pointer ;
  border: none;
  width: 160px;
  height: 32px;
  border-radius: 6px;
  font-family: 'Roboto', sans-serif;  
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #093c5d;
  border: solid 1px #e9ebf1;
}

.contenedorAccionesTitulo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  height: auto;
  margin-left: 22px;
  margin-top: 21px;
}

.pc_botonDispersarInactivo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 32px;
  background-color: #fabb00;
  border: none;
  cursor: pointer;
  border-radius: 8px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #093c5d;
  box-shadow: 0px 2px 8px rgba(250, 187, 0, 0.4);
}

.pc_botonDispersarInactivo:active{
  transition: 10ms;
  background-color: #fddb73;
}

.pc_iconoDispersar{
  background-image: url('/src/assets/svg/icono_derecha_sin.svg');
  width: 4.71px;
  height: 8px;
  margin-left: 10.5px;
}

.contenedorBotones{
  display: flex;
  gap: 5px;
}


.botonarchivo{
  background-color: #fabb00;
  width: 110px;
  height: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #093c5d;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  box-shadow: 0px 2px 8px rgba(250, 187, 0, 0.4);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


/*TABLA IDSE*/

.iconoDocumento{
  background-image: url('../../assets/svg/icono_IDSE_Documento.svg');
  width: 22px;
  height: 28px;
}


.contenedorEstatus{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 13px;
  margin-top: 15px;
}

.estatusAceptado{
  background-color: #daffe3;
  width: 100px;
  height: 2.87px;
  border-radius: 7.2px;
}

.estatusRechazado{
  background-color: #fdc1c1;
  width: 100px;
  height: 2.87px;
  border-radius: 7.2px; 
}


.contenedorInternoEstatus{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.tituloEstatus{
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 500;
  color:#7F7F81;
}

.iconoNoProcesado{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  background-color: #093C5D;
  border-radius: 50%;
  color: white;
  font-family: 'Roboto';
  font-size: 10px;
  cursor: pointer;
}

.columnaEstatus{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
/*botones IDSE*/

.botonEnviarIDSE{
  width: 120px;
  height: 36px;
  background-color: #FABB00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  border-radius: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  color: #093C5D;
  cursor: pointer;
}

.botonDescartarIDSE{
  width: 120px;
  height: 36px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 1px solid #C7CCDC;
  border-radius: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  color: #093C5D;
  cursor: pointer;
}



.iconoEnviarIDSE{
  background-image: url('../../assets/svg/icono_enviarIDSE.svg');
  width: 14px;
  height: 11px;
}

.iconoDescartar{
  background-image: url('../../assets/svg/icono_descartarIDSE.svg');
  width: 10px;
  height: 14px;
}

.contenedorBotonesEnvio{
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconoDescargarIDSE{
  background-image: url('../../assets/svg/icono_descargaDocumentosIDSE.svg');
  height: 16px;
  width: 16px;
}

.botonDescargarDocumento{
  width: 200px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FABB00;
  border-radius: 6px;
  gap: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  color: #093C5D;
  cursor: pointer;
}

.botonEnesperadeRespuesta{
  width: 200px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FABB00;
  border-radius: 6px;
  gap: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  color: #093C5D;
  cursor: pointer;
}
