.oneComponent{
    width: 470px;
    margin-top:2px;    
  }
.twoComponent{
    width: 470px !important;      
    display: flex;
    flex-direction: row;  
    justify-content: space-between;  
    align-items: center;     
}  
.twoComponentBono{
    display: flex;
    flex-direction: row;  
    justify-content: flex-start;  
    align-items: center;   
}
.bonoCampaign{
   width: 150px;
   }
.spanBonoCampaign{
    font-family: "Roboto", sans-serif !important;   
    color:#093C5D ;
   }
 .contTxtBono{
    width: 220px;
 }   
 .textFieldBono{
    margin-top: 8%;
 }  
 .twoComponentBtns{
    width: 470px !important;      
    display: flex;
    flex-direction: row;  
    justify-content: center;  
    align-items: center;     
} 
.btnAdminColaboradorKpi{
    background-color: #9DB1BE;
    border: none;
    cursor: pointer;        
    height: 40px;
    width: 100%;
    border-radius: 8px !important;
    color: rgb(9, 60, 93);
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
    font-family: "Roboto", sans-serif !important;    
    font-weight: 400;
    font-size: 13px;  
    font-style: italic;  
    font-weight: bold;
    margin-bottom: 15px;
}