.container{
    max-height: 40vh !important;
    overflow-y: auto;
}

.rowContainer{
    height: 50px;
    border-radius: 4px;
    border: solid 1px #e9ebf1;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 2px !important;
    margin: 10px;
}

.SedeText{
    text-align: left !important;
    margin-left: 10px !important;
    font-size: 14px;
    color: #222222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* width */
.container::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff; 
}
   
/* Handle */
.container::-webkit-scrollbar-thumb {
    background: rgb(50, 50, 50,0.2);
    border-radius: 2px;
}

.container::-webkit-scrollbar-thumb:hover {
    background: rgb(50, 50, 50,0.2);
}

/* Button Whitout Styles */
.ButtonWhitOutStyles{
    background: none;
    border: none;
}

.ButtonWhitOutStyles:active img{
    opacity: 0;
}

.MuiInput-underline:after, select{
    border-bottom: 2px solid #fabb00 !important;
}

.inline-block div{
    display: inline-flex !important;
    margin: 5px !important;
}

.inline-block div.IconButton:last-child{
    margin-right: 15px !important;
}

/* For the icono with the 3 points */
.MoreVerIcon .MuiPaper-elevation8{
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
}

#AddProject{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#AddProject span{
    height: 16px;
    font-size: 14px;
    color: #222222;
}

#form-dialog-title{
    margin: 17px;
    font-size: 26px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #093c5d !important;
}

#form-dialog-subtitle{
    margin: 5px 0;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #093C5D;
}

#closemodalx{
    position: absolute;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    top: 10px;
    right: 20px;
    text-align: center;
    color: grey;
    cursor: pointer;
    transition-duration: 0.6s;
}