.history-modal .MuiDialog-paper{
  padding:0px 50px 30px;
}

.history-modal .title{
  background: #093C5D;
  border-radius: 5px;
}

.history-modal .MuiPaper-elevation{
  border-radius: 5px;
  box-shadow: none;
}

.history-modal .Mui-expanded{
  margin: 12px 0px !important;
}

.history-modal .MuiAccordionSummary-root{
  background: #093C5D;
  border-radius: 5px;
}

.history-modal .MuiAccordionSummary-content{
  justify-content: center;
}

.history-modal .MuiPaper-elevation h4{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
}

.history-modal .title h4{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  padding:10px;
  margin-top: 0px;
}

#holidays .content{
  padding: 0px 30px;
}

#holidays .table-header{
  background: #E5E9EB;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 20px 0px;
}

#holidays .subtitle{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #303030;
  text-align: center;
  border-right: 1px solid #fff;
}

#holidays .subtitle.no-border{
  border-right: 0px;
}

#holidays .table{
  border: 1px solid #C7CCDC;
  border-radius: 13px;
  margin-top:20px;
}

.table-container{
  padding:20px;
}

#holidays .indicator.column{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #303030;
}

#holidays .column{
  padding: 20px;
  text-align: center;
  font-size: 13px;
}

#holidays .line-below{
  border-bottom: 1px solid #C7CCDC;
}

#timeline{
  margin-top: 20px;
}

#timeline .order-field{
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}

#timeline .MuiSelect-select{
  border-bottom: 0;
}

.history-modal .MuiDialogActions-root{
  justify-content: center;
}

#timeline-row .date-label{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #303030;
  margin-bottom: 0px;
}

#timeline-row .hour-label{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #303030;
  text-align: center;
  margin: 0px;
}

#timeline-row .description-container{
  padding:20px 0px 20px 40px;
  position: relative;
  border-left: 2px solid #C7CCDC;
}

#timeline-row .dot{
  width: 15px;
  height: 15px;
  border-radius: 50px;
  position: absolute;
  left: -7.5px;
  top: 47%;
}

#timeline-row .dot-blue{
  background: #2186C6;
}

#timeline-row .dot-dark-blue{
  background: #093C5D;
}

#timeline-row .description-card{
  background: #FFFFFF;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px 40px;
}

#timeline-row .description-title{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;color: #303030;
  margin-top: 0px;
}

#timeline-row .description-text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #303030;
  margin: 5px 0px 0px;
}