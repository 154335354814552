.table-header-container{
  background: #E9EBF1;
  border-radius: 4px;
  padding:10px;
}

.table-header{
  padding:5px;
  text-align:center;
}

.table-brder-right{
  border-right: 1px solid white;
}

.table-header p{
  margin-bottom: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.361111px;
  color: #303030;
}

.year-label{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12.925px;
  line-height: 15px;
  letter-spacing: 0.359028px;
  color: #303030;
  text-align: center;
  margin: 0px;
}

.input-field{
  border: 0.8px solid #093C5D !important;
  border-radius: 4px;
  width: 60px;
  min-height: 50px;
  display: block!important;
  margin:5px auto !important;
  text-align: center;
}

.politic-table-row{
  border-bottom: 1px solid #C7CCDC;
}

.table-days-container{
  height: 60vh;
  overflow: auto;
}