@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.contenedor{
  width: 854px;
  height: 670px;
}

.contenedorTitulo{
  display: flex;
  padding-left: 200px;
  padding-top: 10px;
}

.tituloCrearEmpresa{
  width: 80%;
  margin-bottom: 20px;
  margin-top: 5px;
}

.tituloCrearEmpresa{
  width: 80%;
  margin-top: 20px;
}

.contenedorTitulo{
  width: auto;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  line-height: 28px;
  color: #093C5D;
  display: flex;
  justify-content: center;
}

.contenedorCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_empresa.svg');
  width: 14px;
  height: 14px;
  margin-left: 200px;
  margin-right: 35px;
  cursor: pointer;
}
.iconoCerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_crearEmpresa.svg');
  width: 13.18px;
  height: 13.18px;
  cursor: pointer;
}

.iconoCerrarModal:hover{
  filter: opacity(0.5) drop-shadow(0 0 0 red); 
}

.titulo{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #093C5D;
}

.contenedorFormulario{
  display: flex;
  justify-content: center;
}

.formularioColumnaUno{
  width: 369px;
  height: auto;
}

.formularioColumnaDos{
  width: 399px;
  height: 445px;
  border: none;
  background-color: #F1F2F5;
  margin-top: 40px;
  border-radius: 8px;
}

.fieldset{
  width: 340px;
  height: 20px;
  bottom: 0.8px solid #C7CCDC;
  border-radius: 4px;
}

.legend{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #303030;
  opacity: 0.6;
}

.contenedorFieldset{
  margin-top: 25px;
  margin-bottom: 48px;
}

.contenedorFieldset0px{
  margin-top: 25px;
  margin-bottom: 0px;
}


.botonCancelar{
  width: 110px;
  height: 34px;
  background-color: #ffffff;
  border: 0.8px solid #E1E1E1;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #093C5D;
  cursor: pointer;
  margin-right: 16px;
}

.botonGuardar{
  width: 110px;
  height: 34px;
  border: none;
  background-color: #FABB00;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #093C5D;
  border-radius: 4px;
  cursor: pointer;
}

.iconoGuardar{
  background-image: url('/src/assets/svg/icono_guardar_sede.svg');
  width: 12px;
  height: 12px;
  margin-right: 12px;
}

.contenedorBotones{
  margin-top: 30px;
  display: flex;
  margin-bottom: 26px;
  padding-bottom: 26PX;
  width: 854px;
  display: flex;
  justify-content: center;
}

.input{
  width: 330px;
  border: none;
  height: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

.input:focus{
  outline: none;
}
.tituloColumnaDos{
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  margin-top: 19px;
}

.contenedorDocumento{
  display: flex;
  align-items: center;
}


.iconoVerDocumento{
  background-image: url('/src/assets/svg/icono_ver_documento_empresa.svg');
  width: 10px;
  height: 7px;
  cursor: pointer;
}

.textoVerDocumento{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #2186c6;
  margin-left: 7px;
  cursor: pointer;
}


.contenedorArchivos{
  width: auto;
  margin-top: 21px;
  margin-left: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 25px;
}

.textoArchivos{
  font-family: 'Roboto',sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.iconoVerDocumento{
  background-image: url('/src/assets/svg/icono_ver_documento_empresa.svg');
  width: 10px;
  height: 7px;
  cursor: pointer;
}

.botonsubirArchivosLlaves{
  background-color: #ffffff;
  border: 0.8px solid #C7CCDC;
  border-radius: 4px;
  width: 150px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #093C5D;
  cursor: pointer;
}

.iconoSubirArchivos{
  background-image: url('/src/assets/svg/icono_subir_archivo_empresa.svg');
  width: 10.67px;
  height: 13.33px;
  margin-right: 8px;
}

.contenedorArchivosDos{
  margin-top: 27px;
}


.botonSeleccionarArchivo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 32px;
  border: none;
  background-color: #FABB00;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #093C5D
}

.contenedorContrasena{
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.contenedorContrasena input{
  text-align: left;
}

.fieldsetContrasena{
  width: 369px;
  padding: 0px 0px;
  height: 42px;
  background-color: #ffffff;
  border: 0.8px solid #C7CCDC;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.fieldsetContrasena > legend {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #303030;
  opacity: 0.6;
}

.fieldsetContrasena > input{
  height: 20px;
  width: 330px;
  border: none;
  padding: 0px 0px;
}

.fieldsetContrasena > input:active{
  outline: none !important;
  border: solid 1px #fff;
}

.fieldsetContrasena > input:focus{
  outline: none !important;
  border: solid 1px #fff;
}

.iconoVerContrasena{
  background-image: url('/src/assets/svg/icono_ver_contrasena.svg');
  height: 10px;
  width: 15px;
  margin-left: 5px;
}

.contenedorRegistro{
  /*width: 420px;*/
  height: auto;
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.contenedorInputsRegistro{
  /*width: 373px;*/
  height: 32px;
  border: 1px solid #C7CCDC;
  border-radius: 4px;
  display: flex;
  /*margin-bottom: 25px;*/
  display: flex;
  align-items: center;
}

.contenedorInputsRegistroRed{
  /*width: 373px;*/
  height: 32px;
  border: 1px solid #f44336;
  border-radius: 4px;
  display: flex;
  /*margin-bottom: 25px;*/
  display: flex;
  align-items: center;
}

.inputRegistroPatronal{
  height: 27px;
  border: none;
  /*width: 184px;*/
}

.inputPrimaRiesgo{
  width: 115px;
  height: 27px;
  border: none;
}

.lineaInputs{
  width: 2px;
  height: 20px;
  background-color: #C7CCDC;
}

.botonAgregarRegistroPatronal{
  /*width: 60px;*/
  margin-right: 5px;
  height: 24px;
  background-color: #FABB00;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.11px;
  color: #093C5D;
}

.contenedorInputLogo{
  width: 100%;
  display: flex;
  height: 40px;
  border: solid 1px #cbcbcb;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;

}

.contenedorInternoLogo{
  height: 40px;

  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: rgb(146,146, 146)
}

.textInternoLogo{
  margin-left: 10px;
}

.contenedorInternoIconoLogoWithFile div{
    height: 42px;
}

.contenedorInternoIconoLogo div{
  height: 46px;
}

.iconoVerContrasena{
  background-image: url('/src/assets/svg/icono_ver_contrasena.svg');
  background-repeat: no-repeat;
  height: 10px;
  width: 18px;
  cursor: pointer;
}

.contenedorRegistros{
  /*width:420px;*/
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: scroll;
}

.lineaRegistro{
  display: flex;
  align-items: center;
  width: 370px;
  height: 32px;
  background-color: #F1F2F5;
  border: 1px solid #C7CDCC;
  margin-bottom: 2px;
}

.lineanombreRegistro{
  width: 164px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.11px;
  color: #303030;
  padding-left: 20px;
}

.lineaDivisionRegistro{
  width: 2px;
  height: 20px;
  background-color: #C7CCDC;
  margin-right: 13.77px;
}

.lineaprimaRiesgo{
  width: 140px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.11px;
  color: #303030;
}

.lineaContenedorEliminar{
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconoLineaPrimaRiesgo{
  background-image: url('/src/assets/svg/icono_eliminar_RegistrosPatronales.svg');
  width: 10px;
  height: 12px;
  cursor: pointer;
}

.validateText{
  color: #f44336;
  margin-left: 32px;
  margin-right: 14px;
  margin-top: 4px;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;

}

.conteinerTextArea{
  height: auto;
  margin-right: 27px;
  margin-left: 27px;
}

.showlogo{
  margin-top: 20px;
}