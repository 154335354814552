.contenedorFiltros{
  width: 100%;
  height: auto;
  display: flex;
}

.contenedorFiltro{
  width: 400px;
  min-width: 400px;
  height: 40px;
  border: 1px solid #c7ccdc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.iconoBuscador{
  background-image: url('/src/assets/svg/icono_buscar_dos.svg');
  width: 18.12px;
  height: 18.12px;
}

.filtro{
  width: 90%;
  height: 35px;
  border:none;
  padding-left: 17px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
}

.filtro::placeholder{
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #303030;
  opacity: 0.6;
}

.filtro:focus{
  border: none !important;
  outline: none;
}


.filtro:active{
  border: none !important;
  outline: none;
}

.select{
  width: auto;
  min-width: 400px;
  height: 42px;
  border: 1px solid #c7ccdc !important;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
  padding-left: 17px;
  margin-left: 46px;
}

/*Contenedor Central*/
.contenedorCentral{
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 26px;
  border-radius: 12px;
  padding-top: 32px;
  padding-right: 26px;
  box-sizing: border-box;
  padding-bottom: 25px;
}

/*Tabla*/
.tabla{
  width: 98%;
  margin-left: 27px;
  border-collapse:collapse;
  margin-top: 29px;
}

.tituloTabla{
  background-color: #e9ebf1;
  height: 32px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #303030;
}


.datosTabla{
  height: 38px;
  border-bottom: solid 1px #c7ccdc;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  line-height: 15.23px;
  letter-spacing: 0.36px;
  color: #303030;
  opacity: 0.8;
}


.iconopdf{
  background-image: url('../../assets/svg/icono_pdf.svg');
  width: 20px;
  height: 25px;
}

.centrado{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}