@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.mainBox {
  padding: 1rem;
}

.mainContainer {
  display: flex;
  justify-content: flex-end;
}

.closeModalButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.formControlContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.formLabel {
  background-color: white;
}

.select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  height: 40px;
}

.selectOptionsCard {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
}

.checkbox {
  color: #FABB00;
}

.checkboxLabel {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.3055555522441864px;
  text-align: left;
  color: #303030;
}

.cancelButton {
  width: 168px;
  height: 38px;
  border-radius: 6px;
  background: white;
  border: 1px solid #c7ccdc;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  color: #093c5d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}